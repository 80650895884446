import { FormControl, IconButton, InputLabel } from '@mui/material';
import MuiInput from '@mui/material/Input/Input';
import React from 'react';

import { Body } from '../Typography/Typography';

import { InputProps } from './Input.props';
import { InputLabelStyles, InputStyles } from './Input.styles';

export const Input: React.FC<InputProps> = props => {
  const { endAdornment, onAdornmentClick, helperText, secondary, placeholder, ...rest } = props;
  const classes = InputStyles(props);
  const inputLabelClasses = InputLabelStyles(props);

  return (
    <>
      <FormControl fullWidth>
        {!secondary && (
          <InputLabel classes={inputLabelClasses} disabled={props.disabled}>
            {placeholder}
          </InputLabel>
        )}
        <MuiInput
          classes={classes}
          placeholder={secondary ? placeholder : undefined}
          endAdornment={
            endAdornment ? (
              <IconButton tabIndex={-1} classes={{ root: classes.iconButtonRoot }} onClick={onAdornmentClick}>
                {endAdornment}
              </IconButton>
            ) : null
          }
          {...rest}
        />
      </FormControl>
      {helperText && (
        <Body color="lightCoral" className={classes.helperText}>
          {helperText}
        </Body>
      )}
    </>
  );
};
