import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useStores } from '@bpm/mobx/useStores';
import { ViewPatientRouteParams } from '@bpm/pages/protected/view-patient/index.props';
import useOnMount from '@bpm/utils/hooks/useOnMount';

export const PatientLayout: React.FC = () => {
  const { patientsStore, authStore } = useStores();
  const { id } = useParams<ViewPatientRouteParams>();
  const { user } = authStore;
  const { isDoctor } = user!;

  useOnMount(() => {
    let patientId = id;
    if (!isDoctor) {
      patientId = user!.patient.id;
    }

    patientsStore.loadPatient(Number(patientId));
  });

  return <Outlet />;
};
