/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { QueryBuilder } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { RootStoreType } from './index';

/**
 * RemoveOutputBase
 * auto generated base class for the model RemoveOutputModel.
 */
export const RemoveOutputModelBase = ModelBase.named('RemoveOutput')
  .props({
    __typename: types.optional(types.literal('RemoveOutput'), 'RemoveOutput'),
    affected: types.union(types.undefined, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>();
    },
  }));

export class RemoveOutputModelSelector extends QueryBuilder {
  get affected() {
    return this.__attr(`affected`);
  }
}
export function selectFromRemoveOutput() {
  return new RemoveOutputModelSelector();
}

export const removeOutputModelPrimitives = selectFromRemoveOutput().affected;
