import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { colors } from '@bpm/ui-kit/theme';
import { getMediaQueryForPhone } from '@bpm/utils/MediaQuery';

export const DashboardLayoutStyles = makeStyles<Theme>(theme => {
  const mobile = getMediaQueryForPhone(theme);

  return {
    root: {
      maxWidth: 1400,
      minHeight: '100vh',
      padding: 30,
      margin: '0 auto',
      [mobile]: {
        padding: '16px 8px',
      },
    },
    menuContainer: {
      width: 290,
      position: 'sticky',
      top: 12,
    },
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    importPopperContainer: {
      marginLeft: 40,
    },
    childrenContainer: {
      [mobile]: {
        padding: '0 8px',
      },
    },
    svgIcon: {
      [mobile]: {
        width: 17,
        height: 17,
      },
    },
  };
});

export const MenuItemStyles = makeStyles<Theme, { isActiveRoute: boolean }>(theme => ({
  root: ({ isActiveRoute }) => ({
    padding: 12,
    color: isActiveRoute ? colors.white : colors.black,
    boxShadow: isActiveRoute ? `0 1px 8px 0 ${colors.primaryHover}99` : 'none',

    '&, &:hover': {
      backgroundColor: isActiveRoute ? colors.primary : colors.white,
    },
  }),
}));

export const HeaderStyles = makeStyles<Theme>(theme => {
  const mobile = getMediaQueryForPhone(theme);

  return {
    avatarContainer: {
      marginRight: 20,
      [mobile]: {
        marginRight: 0,
      },
    },
    avatar: {
      backgroundColor: colors.primary,
      width: 50,
      height: 50,
      [mobile]: {
        width: 32,
        height: 32,
      },
    },
  };
});

export const PopperContentStyles = makeStyles<Theme>(theme => {
  const mobile = getMediaQueryForPhone(theme);

  return {
    root: {
      marginTop: 8,
      padding: 20,
      backgroundColor: colors.primary,
      [mobile]: {
        backgroundColor: colors.white,
      },
    },
    languageSwitcher: {
      position: 'fixed',
      bottom: 20,
    },
  };
});
