import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { AppLinkProps } from '@bpm/components/AppLink/AppLink.props';
import { AppLinkStyles } from '@bpm/components/AppLink/AppLink.styles';
import { useTranslation } from '@bpm/i18n';

export const AppLink: React.FC<AppLinkProps> = props => {
  const { to, className, ...rest } = props;
  const { localePath } = useTranslation();
  const classes = AppLinkStyles(props);

  return <Link className={clsx(classes.root, className)} to={localePath(to as string)} {...rest} />;
};
