import { useMediaQuery, useTheme } from '@mui/material';
import { Options } from '@mui/material/useMediaQuery/useMediaQuery';

import { getMediaQueryForDesktop, getMediaQueryForTablet, getMediaQueryForPhone } from '../MediaQuery';

const defaultOptions: Options = { noSsr: true };

export const usePhoneBreakpoint = (options?: Options) => {
  const theme = useTheme();
  const mediaQuery = getMediaQueryForPhone(theme);

  return useMediaQuery(mediaQuery, options || defaultOptions);
};

export const useTabletBreakpoint = (options?: Options) => {
  const theme = useTheme();
  const mediaQuery = getMediaQueryForTablet(theme);

  return useMediaQuery(mediaQuery, options || defaultOptions);
};

export const useDesktopBreakpoint = (options?: Options) => {
  const theme = useTheme();
  const mediaQuery = getMediaQueryForDesktop(theme);

  return useMediaQuery(mediaQuery, options || defaultOptions);
};
