/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { ObservableMap } from 'mobx';
import { types } from 'mobx-state-tree';
import { MSTGQLStore, configureStoreMixin, QueryOptions, withTypedRefs } from 'mst-gql';

import { CardioLosesChartOutputModel, CardioLosesChartOutputModelType } from './CardioLosesChartOutputModel';
import {
  cardioLosesChartOutputModelPrimitives,
  CardioLosesChartOutputModelSelector,
} from './CardioLosesChartOutputModel.base';
import { CardioPieChartOutputModel, CardioPieChartOutputModelType } from './CardioPieChartOutputModel';
import {
  cardioPieChartOutputModelPrimitives,
  CardioPieChartOutputModelSelector,
} from './CardioPieChartOutputModel.base';
import { DoctorModel, DoctorModelType } from './DoctorModel';
import { doctorModelPrimitives, DoctorModelSelector } from './DoctorModel.base';
import { EmailOutputModel, EmailOutputModelType } from './EmailOutputModel';
import { emailOutputModelPrimitives, EmailOutputModelSelector } from './EmailOutputModel.base';
import { LoginOutputModel, LoginOutputModelType } from './LoginOutputModel';
import { loginOutputModelPrimitives, LoginOutputModelSelector } from './LoginOutputModel.base';
import { PatientModel, PatientModelType } from './PatientModel';
import { patientModelPrimitives, PatientModelSelector } from './PatientModel.base';
import { PredictionChartOutputModel, PredictionChartOutputModelType } from './PredictionChartOutputModel';
import {
  predictionChartOutputModelPrimitives,
  PredictionChartOutputModelSelector,
} from './PredictionChartOutputModel.base';
import { RemoveOutputModel, RemoveOutputModelType } from './RemoveOutputModel';
import { removeOutputModelPrimitives, RemoveOutputModelSelector } from './RemoveOutputModel.base';
import { ResetPasswordOutputModel, ResetPasswordOutputModelType } from './ResetPasswordOutputModel';
import { resetPasswordOutputModelPrimitives, ResetPasswordOutputModelSelector } from './ResetPasswordOutputModel.base';
import { RiskLevelChartOutputModel, RiskLevelChartOutputModelType } from './RiskLevelChartOutputModel';
import {
  riskLevelChartOutputModelPrimitives,
  RiskLevelChartOutputModelSelector,
} from './RiskLevelChartOutputModel.base';
import { StatisticsChartOutputModel, StatisticsChartOutputModelType } from './StatisticsChartOutputModel';
import {
  statisticsChartOutputModelPrimitives,
  StatisticsChartOutputModelSelector,
} from './StatisticsChartOutputModel.base';
import { SuccessOutputModel, SuccessOutputModelType } from './SuccessOutputModel';
import { successOutputModelPrimitives, SuccessOutputModelSelector } from './SuccessOutputModel.base';
import { TimeserieModel, TimeserieModelType } from './TimeserieModel';
import { timeserieModelPrimitives, TimeserieModelSelector } from './TimeserieModel.base';
import { UserModel, UserModelType } from './UserModel';
import { userModelPrimitives, UserModelSelector } from './UserModel.base';

export type CreateDoctorInput = {
  company: string;
  user: number;
};
export type CreatePatientInput = {
  age: number;
  cholesterol?: number;
  comment?: string;
  currentTreatments?: string[];
  firstName: string;
  gender: string;
  glucose?: number;
  habitsAndComplaints?: string[];
  height: number;
  hereditaryPressure: number;
  lastName: string;
  pregnancy: boolean;
  prescriptionDrugs?: string[];
  presentDiseases?: string[];
  sideEffects: boolean;
  symptoms?: string[];
  temperature: number;
  userId?: number;
  weight: number;
};
export type CreateTimeserieInput = {
  activity?: number;
  arrhythmia?: boolean;
  comment?: string;
  diastolic: number;
  headAche?: boolean;
  healthyDiet?: boolean;
  irregularHeartBeat?: boolean;
  measureDate?: any;
  patientId: number;
  stress?: boolean;
  systolic: number;
  weakness?: boolean;
};
export type CreateUserInput = {
  company?: string;
  email: string;
  name: string;
  password: string;
  repeatPassword: string;
};
export type InviteUserInput = {
  email: string;
  name: string;
};
export type LoginInput = {
  email: string;
  password: string;
};
export type PredictionChartInput = {
  patientId: number;
  timeserieSize: number;
};
export type ResetPasswordInput = {
  newPassword: string;
  repeatPassword: string;
  token: string;
};
export type SharePatientInput = {
  email: string;
};
export type StatisticsChartInput = {
  bmi?: number;
  habits: string[];
  maxAge: number;
  minAge: number;
  riskLevel?: number;
  sex: string;
};
export type UpdatePatientInput = {
  age?: number;
  cholesterol?: number;
  comment?: string;
  currentTreatments?: string[];
  firstName?: string;
  gender?: string;
  glucose?: number;
  habitsAndComplaints?: string[];
  height?: number;
  hereditaryPressure?: number;
  id: string;
  lastName?: string;
  pregnancy?: boolean;
  prescriptionDrugs?: string[];
  presentDiseases?: string[];
  sideEffects?: boolean;
  symptoms?: string[];
  temperature?: number;
  userId?: number;
  weight?: number;
};
/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  doctors: ObservableMap<string, DoctorModelType>;
  patients: ObservableMap<string, PatientModelType>;
  timeseries: ObservableMap<string, TimeserieModelType>;
  users: ObservableMap<string, UserModelType>;
};

/**
 * Enums for the names of base graphql actions
 */
export enum RootStoreBaseQueries {
  queryCardioLosesChartStatistics = 'queryCardioLosesChartStatistics',
  queryCardioPieChartStatistics = 'queryCardioPieChartStatistics',
  queryDoctors = 'queryDoctors',
  queryPatient = 'queryPatient',
  queryPatients = 'queryPatients',
  queryPrediction = 'queryPrediction',
  queryRisklevel = 'queryRisklevel',
  queryStatistics = 'queryStatistics',
  queryWhoAmI = 'queryWhoAmI',
}
export enum RootStoreBaseMutations {
  mutateCreateDoctor = 'mutateCreateDoctor',
  mutateCreatePatient = 'mutateCreatePatient',
  mutateCreateTimeserie = 'mutateCreateTimeserie',
  mutateCreateUser = 'mutateCreateUser',
  mutateForgotPassword = 'mutateForgotPassword',
  mutateInviteUser = 'mutateInviteUser',
  mutateLogIn = 'mutateLogIn',
  mutateRemovePatient = 'mutateRemovePatient',
  mutateRemoveTimeserie = 'mutateRemoveTimeserie',
  mutateResetPassword = 'mutateResetPassword',
  mutateSharePatientToDoctor = 'mutateSharePatientToDoctor',
  mutateUpdatePatient = 'mutateUpdatePatient',
}

/**
 * Store, managing, among others, all the objects received through graphQL
 */
export const RootStoreBase = withTypedRefs<Refs>()(
  MSTGQLStore.named('RootStore')
    .extend(
      configureStoreMixin(
        [
          ['CardioLosesChartOutput', () => CardioLosesChartOutputModel],
          ['CardioPieChartOutput', () => CardioPieChartOutputModel],
          ['Doctor', () => DoctorModel],
          ['EmailOutput', () => EmailOutputModel],
          ['LoginOutput', () => LoginOutputModel],
          ['Patient', () => PatientModel],
          ['PredictionChartOutput', () => PredictionChartOutputModel],
          ['RemoveOutput', () => RemoveOutputModel],
          ['ResetPasswordOutput', () => ResetPasswordOutputModel],
          ['RiskLevelChartOutput', () => RiskLevelChartOutputModel],
          ['StatisticsChartOutput', () => StatisticsChartOutputModel],
          ['SuccessOutput', () => SuccessOutputModel],
          ['Timeserie', () => TimeserieModel],
          ['User', () => UserModel],
        ],
        ['Doctor', 'Patient', 'Timeserie', 'User'],
        'js',
      ),
    )
    .props({
      doctors: types.optional(types.map(types.late((): any => DoctorModel)), {}),
      patients: types.optional(types.map(types.late((): any => PatientModel)), {}),
      timeseries: types.optional(types.map(types.late((): any => TimeserieModel)), {}),
      users: types.optional(types.map(types.late((): any => UserModel)), {}),
    })
    .actions(self => ({
      queryCardioLosesChartStatistics(
        variables: { statisticsChartInput: StatisticsChartInput },
        resultSelector:
          | string
          | ((
              qb: CardioLosesChartOutputModelSelector,
            ) => CardioLosesChartOutputModelSelector) = cardioLosesChartOutputModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ cardioLosesChartStatistics: CardioLosesChartOutputModelType }>(
          `query cardioLosesChartStatistics($statisticsChartInput: StatisticsChartInput!) { cardioLosesChartStatistics(statisticsChartInput: $statisticsChartInput) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CardioLosesChartOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        );
      },
      queryCardioPieChartStatistics(
        variables: { statisticsChartInput: StatisticsChartInput },
        resultSelector:
          | string
          | ((
              qb: CardioPieChartOutputModelSelector,
            ) => CardioPieChartOutputModelSelector) = cardioPieChartOutputModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ cardioPieChartStatistics: CardioPieChartOutputModelType[] }>(
          `query cardioPieChartStatistics($statisticsChartInput: StatisticsChartInput!) { cardioPieChartStatistics(statisticsChartInput: $statisticsChartInput) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CardioPieChartOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        );
      },
      queryDoctors(
        variables?: {},
        resultSelector: string | ((qb: DoctorModelSelector) => DoctorModelSelector) = doctorModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ doctors: DoctorModelType[] }>(
          `query doctors { doctors {
        ${typeof resultSelector === 'function' ? resultSelector(new DoctorModelSelector()).toString() : resultSelector}
      } }`,
          variables,
          options,
        );
      },
      queryPatient(
        variables: { id: number },
        resultSelector:
          | string
          | ((qb: PatientModelSelector) => PatientModelSelector) = patientModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ patient: PatientModelType }>(
          `query patient($id: Int!) { patient(id: $id) {
        ${typeof resultSelector === 'function' ? resultSelector(new PatientModelSelector()).toString() : resultSelector}
      } }`,
          variables,
          options,
        );
      },
      queryPatients(
        variables?: {},
        resultSelector:
          | string
          | ((qb: PatientModelSelector) => PatientModelSelector) = patientModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ patients: PatientModelType[] }>(
          `query patients { patients {
        ${typeof resultSelector === 'function' ? resultSelector(new PatientModelSelector()).toString() : resultSelector}
      } }`,
          variables,
          options,
        );
      },
      queryPrediction(
        variables: { predictionChartInput: PredictionChartInput },
        resultSelector:
          | string
          | ((
              qb: PredictionChartOutputModelSelector,
            ) => PredictionChartOutputModelSelector) = predictionChartOutputModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ prediction: PredictionChartOutputModelType }>(
          `query prediction($predictionChartInput: PredictionChartInput!) { prediction(predictionChartInput: $predictionChartInput) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new PredictionChartOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        );
      },
      queryRisklevel(
        variables: { predictionChartInput: PredictionChartInput },
        resultSelector:
          | string
          | ((
              qb: RiskLevelChartOutputModelSelector,
            ) => RiskLevelChartOutputModelSelector) = riskLevelChartOutputModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ risklevel: RiskLevelChartOutputModelType }>(
          `query risklevel($predictionChartInput: PredictionChartInput!) { risklevel(predictionChartInput: $predictionChartInput) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new RiskLevelChartOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        );
      },
      queryStatistics(
        variables: { statisticsChartInput: StatisticsChartInput },
        resultSelector:
          | string
          | ((
              qb: StatisticsChartOutputModelSelector,
            ) => StatisticsChartOutputModelSelector) = statisticsChartOutputModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ statistics: StatisticsChartOutputModelType }>(
          `query statistics($statisticsChartInput: StatisticsChartInput!) { statistics(statisticsChartInput: $statisticsChartInput) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new StatisticsChartOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        );
      },
      queryWhoAmI(
        variables?: {},
        resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ whoAmI: UserModelType }>(
          `query whoAmI { whoAmI {
        ${typeof resultSelector === 'function' ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`,
          variables,
          options,
        );
      },
      mutateCreateDoctor(
        variables: { createDoctorInput: CreateDoctorInput },
        resultSelector: string | ((qb: DoctorModelSelector) => DoctorModelSelector) = doctorModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createDoctor: DoctorModelType }>(
          `mutation createDoctor($createDoctorInput: CreateDoctorInput!) { createDoctor(createDoctorInput: $createDoctorInput) {
        ${typeof resultSelector === 'function' ? resultSelector(new DoctorModelSelector()).toString() : resultSelector}
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateCreatePatient(
        variables: { createPatientInput: CreatePatientInput },
        resultSelector:
          | string
          | ((qb: PatientModelSelector) => PatientModelSelector) = patientModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createPatient: PatientModelType }>(
          `mutation createPatient($createPatientInput: CreatePatientInput!) { createPatient(createPatientInput: $createPatientInput) {
        ${typeof resultSelector === 'function' ? resultSelector(new PatientModelSelector()).toString() : resultSelector}
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateCreateTimeserie(
        variables: { createTimeserieInput: CreateTimeserieInput },
        resultSelector:
          | string
          | ((qb: TimeserieModelSelector) => TimeserieModelSelector) = timeserieModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createTimeserie: TimeserieModelType }>(
          `mutation createTimeserie($createTimeserieInput: CreateTimeserieInput!) { createTimeserie(createTimeserieInput: $createTimeserieInput) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new TimeserieModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateCreateUser(
        variables: { createUserInput: CreateUserInput },
        resultSelector: string | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createUser: UserModelType }>(
          `mutation createUser($createUserInput: CreateUserInput!) { createUser(createUserInput: $createUserInput) {
        ${typeof resultSelector === 'function' ? resultSelector(new UserModelSelector()).toString() : resultSelector}
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateForgotPassword(
        variables: { email: string },
        resultSelector:
          | string
          | ((qb: SuccessOutputModelSelector) => SuccessOutputModelSelector) = successOutputModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ forgotPassword: SuccessOutputModelType }>(
          `mutation forgotPassword($email: String!) { forgotPassword(email: $email) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new SuccessOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateInviteUser(
        variables: { inviteUserInput: InviteUserInput },
        resultSelector:
          | string
          | ((qb: EmailOutputModelSelector) => EmailOutputModelSelector) = emailOutputModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ inviteUser: EmailOutputModelType }>(
          `mutation inviteUser($inviteUserInput: InviteUserInput!) { inviteUser(inviteUserInput: $inviteUserInput) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new EmailOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateLogIn(
        variables: { loginInput: LoginInput },
        resultSelector:
          | string
          | ((qb: LoginOutputModelSelector) => LoginOutputModelSelector) = loginOutputModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ logIn: LoginOutputModelType }>(
          `mutation logIn($loginInput: LoginInput!) { logIn(loginInput: $loginInput) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LoginOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateRemovePatient(
        variables: { id: number },
        resultSelector:
          | string
          | ((qb: RemoveOutputModelSelector) => RemoveOutputModelSelector) = removeOutputModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ removePatient: RemoveOutputModelType }>(
          `mutation removePatient($id: Int!) { removePatient(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new RemoveOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateRemoveTimeserie(
        variables: { id: number },
        resultSelector:
          | string
          | ((qb: RemoveOutputModelSelector) => RemoveOutputModelSelector) = removeOutputModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ removeTimeserie: RemoveOutputModelType }>(
          `mutation removeTimeserie($id: Int!) { removeTimeserie(id: $id) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new RemoveOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateResetPassword(
        variables: { resetPasswordInput: ResetPasswordInput },
        resultSelector:
          | string
          | ((
              qb: ResetPasswordOutputModelSelector,
            ) => ResetPasswordOutputModelSelector) = resetPasswordOutputModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ resetPassword: ResetPasswordOutputModelType }>(
          `mutation resetPassword($resetPasswordInput: ResetPasswordInput!) { resetPassword(resetPasswordInput: $resetPasswordInput) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ResetPasswordOutputModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateSharePatientToDoctor(
        variables: { sharePatientInput: SharePatientInput },
        resultSelector:
          | string
          | ((qb: PatientModelSelector) => PatientModelSelector) = patientModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ sharePatientToDoctor: PatientModelType }>(
          `mutation sharePatientToDoctor($sharePatientInput: SharePatientInput!) { sharePatientToDoctor(sharePatientInput: $sharePatientInput) {
        ${typeof resultSelector === 'function' ? resultSelector(new PatientModelSelector()).toString() : resultSelector}
      } }`,
          variables,
          optimisticUpdate,
        );
      },
      mutateUpdatePatient(
        variables: { updatePatientInput: UpdatePatientInput },
        resultSelector:
          | string
          | ((qb: PatientModelSelector) => PatientModelSelector) = patientModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updatePatient: PatientModelType }>(
          `mutation updatePatient($updatePatientInput: UpdatePatientInput!) { updatePatient(updatePatientInput: $updatePatientInput) {
        ${typeof resultSelector === 'function' ? resultSelector(new PatientModelSelector()).toString() : resultSelector}
      } }`,
          variables,
          optimisticUpdate,
        );
      },
    })),
);
