import React from 'react';

import { DrawerLayout } from '@bpm/pages/protected/components/DashboardLayout/DrawerLayout/DrawerLayout';
import { IconMenuStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconMenuStandard';

import { Sidebar } from './Sidebar';

export const MobileBurgerMenu: React.FC = () => (
  <DrawerLayout DrawerMenuContentComponent={Sidebar}>
    <IconMenuStandard />
  </DrawerLayout>
);
