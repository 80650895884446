import * as Yup from 'yup';

import { FormFieldsProps } from './index.props';

export const formInitialValues: FormFieldsProps = {
  email: '',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter valid email!').required('Email field is required!'),
});
