import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { StoreProvider } from '@bpm/mobx/RootStore';
import { createAppStore } from '@bpm/mobx/configureStore';
import { defaultTheme } from '@bpm/ui-kit/theme/theme';

import App from './App';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './static/fonts/fonts.css';

ReactDOM.render(
  <StoreProvider value={createAppStore()}>
    <LocalizationProvider dateAdapter={DateAdapter}>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <ThemeProvider theme={defaultTheme}>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </ThemeProvider>
        </BrowserRouter>
      </StyledEngineProvider>
    </LocalizationProvider>
  </StoreProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
