import MuiButton from '@mui/material/Button';
import React, { forwardRef } from 'react';

import { ButtonProps } from './Button.props';
import { ButtonStyles } from './Button.styles';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { secondary, disabled, ...rest } = props;
  const classes = ButtonStyles(props);

  return (
    <MuiButton
      ref={ref}
      classes={classes}
      variant={'contained'}
      color={secondary ? 'secondary' : 'primary'}
      {...rest}
    />
  );
});
