import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { AppLink } from '@bpm/components/AppLink/AppLink';
import { Spacer } from '@bpm/components/Spacer/Spacer';
import { useTranslation } from '@bpm/i18n';
import { UserModelType } from '@bpm/mobx/graphql';
import { useStores } from '@bpm/mobx/useStores';
import { routeTable } from '@bpm/routes/constants';
import { Button } from '@bpm/ui-kit/components/Button/Button';
import { Input } from '@bpm/ui-kit/components/Input/Input';
import { Body, Heading } from '@bpm/ui-kit/components/Typography/Typography';
import { IconEyeHideStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconEyeHideStandard';
import { IconEyeVewStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconEyeVewStandard';
import { colors } from '@bpm/ui-kit/theme';
import useNavigation from '@bpm/utils/hooks/useNavigation';

import { FormFieldsProps } from './index.props';

const formInitialValues: FormFieldsProps = {
  username: '',
  password: '',
};

export const getEntryPointPath = (user?: UserModelType) => {
  if (!user) {
    return;
  }

  if (user.isDoctor) {
    return routeTable.dashboard;
  }

  return user.patient ? routeTable.patient : routeTable.addPatient;
};

const LoginForm: React.FC = observer(() => {
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation('signing');
  const [searchParams] = useSearchParams();
  const { switchTo } = useNavigation();
  const { authStore } = useStores();
  const { login, loadingStatus } = authStore;

  const IconEyeView = <IconEyeVewStandard color={colors.easterBlue} />;
  const IconEyeHide = <IconEyeHideStandard color={colors.easterBlue} />;

  const validationSchema = Yup.object().shape({
    username: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Please provide your password'),
  });

  useEffect(() => {
    const path = getEntryPointPath(authStore.user);
    path && switchTo(path, { replace: true });
  }, [authStore.user, switchTo]);

  const submitForm = ({ username, password }: FormFieldsProps) => {
    return login(username, password);
  };

  return (
    <Formik
      initialValues={{ ...formInitialValues, username: searchParams.get('email') ?? '' }}
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      {form => (
        <Form onSubmit={form.handleSubmit} noValidate>
          <Heading>{t('login')}</Heading>

          <Spacer mt={50} mb={20}>
            <Input
              fullWidth
              autoFocus
              type="email"
              name="username"
              value={form.values.username}
              onChange={form.handleChange}
              placeholder={t('email')}
              error={form.touched.username && !!form.errors.username}
              helperText={form.touched.username && form.errors.username}
            />
          </Spacer>
          <Spacer my={20}>
            <Input
              fullWidth
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={form.values.password}
              onChange={form.handleChange}
              placeholder={t('password')}
              onAdornmentClick={() => setShowPassword(!showPassword)}
              endAdornment={showPassword ? IconEyeView : IconEyeHide}
              error={form.touched.password && !!form.errors.password}
              helperText={form.touched.password && form.errors.password}
            />
          </Spacer>

          <Grid container justifyContent="flex-end">
            <AppLink to={routeTable.forgotPassword}>{t('forgotPassword')}</AppLink>
          </Grid>

          {loadingStatus.isError && (
            <Body isBold color="lightCoral">
              {loadingStatus.error}
            </Body>
          )}

          <Spacer my={24}>
            <Button fullWidth type="submit" disabled={form.isSubmitting}>
              {t('login')}
            </Button>
          </Spacer>

          <Grid container justifyContent="center">
            <AppLink to={routeTable.signup}>{t('signUp')}</AppLink>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});

const Login: React.FC = () => <LoginForm />;

export default Login;
