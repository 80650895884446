import { Avatar, ClickAwayListener, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';

import { AppLink } from '@bpm/components/AppLink/AppLink';
import { LanguageSwitcher } from '@bpm/components/LanguageSwitcher/LanguageSwitcher';
import { Logo } from '@bpm/components/Logo/Logo';
import { Spacer } from '@bpm/components/Spacer/Spacer';
import { useStores } from '@bpm/mobx/useStores';
import { HeaderMenuContent } from '@bpm/pages/protected/components/DashboardLayout/HeaderMenuContent';
import { MobileUserMenu } from '@bpm/pages/protected/components/DashboardLayout/MobileUserMenu';
import { routeTable } from '@bpm/routes/constants';
import { Popper } from '@bpm/ui-kit/components/Popper';
import { usePhoneBreakpoint } from '@bpm/utils/hooks/theme';

import { DashboardLayoutProps } from './DashboardLayout.props';
import { DashboardLayoutStyles, HeaderStyles } from './DashboardLayout.styles';
import { MobileBurgerMenu } from './MobileBurgerMenu';
import { Sidebar } from './Sidebar';

const Header: React.FC = () => {
  const classes = HeaderStyles();
  const { authStore } = useStores();
  const { user } = authStore;
  const isMobile = usePhoneBreakpoint();

  const name = user?.initials;
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onAvatarClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsPopperOpen(!isPopperOpen);
    setAnchorEl(event.currentTarget);
  };

  const onClickAwayClick = () => {
    if (isPopperOpen) {
      setIsPopperOpen(false);
    }
  };

  if (isMobile) {
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item display="flex" alignItems="center">
          <MobileBurgerMenu />
        </Grid>
        <Grid item display="flex" alignItems="center">
          <AppLink to={routeTable.dashboard}>
            <Logo width={61} />
          </AppLink>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <div className={classes.avatarContainer}>
            <MobileUserMenu user={name || ''} className={classes.avatar} />
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item display="flex" alignItems="center">
        <AppLink to={routeTable.dashboard}>
          <Logo />
        </AppLink>
      </Grid>
      <Grid item display="flex" alignItems="center">
        <div className={classes.avatarContainer}>
          <IconButton onClick={onAvatarClick}>
            <Avatar alt={name} className={classes.avatar}>
              {name}
            </Avatar>
          </IconButton>
        </div>
        <Popper open={isPopperOpen} anchorEl={anchorEl} placement="bottom">
          <ClickAwayListener onClickAway={onClickAwayClick}>
            <div>
              <HeaderMenuContent onClose={onClickAwayClick} />
            </div>
          </ClickAwayListener>
        </Popper>

        <LanguageSwitcher />
      </Grid>
    </Grid>
  );
};

export const DashboardLayout: React.FC<DashboardLayoutProps> = props => {
  const { children } = props;
  const classes = DashboardLayoutStyles(props);
  const isMobile = usePhoneBreakpoint();

  return (
    <div className={classes.root}>
      <Spacer mb={20}>
        <Header />
      </Spacer>
      <Grid container className={classes.childrenContainer}>
        {!isMobile && (
          <Grid item>
            <Sidebar />
          </Grid>
        )}
        <Grid item display="flex" flex={1}>
          <Spacer ml={isMobile ? 0 : 38} className={classes.container}>
            {children}
          </Spacer>
        </Grid>
      </Grid>
    </div>
  );
};
