import React from 'react';

export const capitalize = (target: string) => target.charAt(0).toUpperCase() + target.slice(1);

/**
 * Helper function that converts any string into camel case.
 */
export const camelize = (target: string) =>
  target
    // Replaces any - or _ characters with a space
    .replace(/[-_]+/g, ' ')
    // Uppercase's the first character in each group immediately following a space
    // (delimited by spaces)
    .replace(/ (.)/g, function ($1) {
      return $1.toUpperCase();
    })
    // Removes spaces
    .replace(/ /g, '');

/**
 * Helper function that replaces substring with ReactNode
 */
export const replaceJSX = (str: string, find: string, replace: string | React.ReactNode) => {
  if (str.includes(find)) {
    const parts = [];
    const startIndex = str.indexOf(find);

    parts.push(str.slice(0, startIndex));
    parts.push(replace);
    parts.push(str.slice(startIndex + find.length, str.length));

    return parts;
  }

  return str;
};

/**
 * Helper function that removes year from date
 * only if date is in the following format: DD-MM-YYY
 */
export const dropYear = (date: string) => date.split('-').slice(0, 2).join('-');
