import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import React from 'react';

import { IconCheckboxEmptyStandard } from '../icons/iconComponents/IconCheckboxEmptyStandard';
import { IconCheckboxMarkedStandard } from '../icons/iconComponents/IconCheckboxMarkedStandard';

import { CheckboxStyles } from './Checkbox.styles';

export const Checkbox: React.FC<CheckboxProps> = props => {
  const { ...rest } = props;
  const classes = CheckboxStyles(props);

  return (
    <MuiCheckbox
      classes={classes}
      icon={<IconCheckboxEmptyStandard />}
      checkedIcon={<IconCheckboxMarkedStandard />}
      {...rest}
    />
  );
};
