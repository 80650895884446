import { flow, types } from 'mobx-state-tree';

import { withEnvironment } from '@bpm/mobx/extensions/withEnvironment';
import { withGraphql } from '@bpm/mobx/extensions/withGraphql';
import { LoginOutputModelSelector, ResetPasswordOutputModelSelector, UserModelType } from '@bpm/mobx/graphql';
import { CreateUserInput } from '@bpm/mobx/graphql/RootStore.base';
import { LoadingStatus } from '@bpm/mobx/models/LoadingStatus';
import { MSTInstance } from '@bpm/mobx/types/mst';
import { extractError } from '@bpm/utils/errors';

const AuthModel = types
  .model('AuthStore', {
    userId: types.maybe(types.string),
    loadingStatus: types.optional(LoadingStatus, {}),
  })
  .extend(withGraphql)
  .extend(withEnvironment)
  .views(self => ({
    get user(): UserModelType | undefined {
      if (!self.userId) {
        return;
      }
      return self.graphql.users.get(self.userId);
    },
  }))
  .actions(self => ({
    setup() {
      const token = localStorage.getItem('token');
      if (token) {
        self.env.setToken(token);
      }
    },
    logout() {
      self.userId = undefined;
      self.env.clearToken();
    },
  }))
  .actions(self => ({
    whoAmI: flow(function* () {
      self.setup();
      const data = yield self.graphql
        .queryWhoAmI({}, user => user.id.email.role.patient(patient => patient.id).doctor(doctor => doctor.id), {
          fetchPolicy: 'no-cache',
        })
        .promise.catch(error => {
          console.warn('whoAmI error', error);
          self.logout();
        });

      if (!data) {
        self.logout();
        return;
      }
      self.userId = data.whoAmI.id;
    }),
  }))
  .actions(self => ({
    registerUser: flow(function* (createUserInput: CreateUserInput) {
      yield self.graphql.mutateCreateUser({
        createUserInput,
      }).promise;
    }),

    login: flow(function* (username: string, password: string) {
      self.loadingStatus.start();

      try {
        const response = yield self.graphql.mutateLogIn({ loginInput: { email: username, password } }).promise;
        const loginOutput: LoginOutputModelSelector = response.logIn;

        localStorage.setItem('token', loginOutput.token.toString());

        yield self.whoAmI();

        self.loadingStatus.finish();
      } catch (err) {
        self.loadingStatus.fail(extractError(err));
      }
    }),

    forgotPassword: flow(function* (email: string) {
      self.loadingStatus.start();

      try {
        yield self.graphql.mutateForgotPassword({ email }).promise;

        self.loadingStatus.finish();
      } catch (err) {
        self.loadingStatus.fail(extractError(err));
      }
    }),

    resetPassword: flow(function* (token: string, newPassword: string, repeatPassword: string) {
      self.loadingStatus.start();

      try {
        const response = yield self.graphql.mutateResetPassword({
          resetPasswordInput: { token, newPassword, repeatPassword },
        }).promise;
        const resetPasswordOutput: ResetPasswordOutputModelSelector = response.resetPassword;

        localStorage.setItem('token', resetPasswordOutput.token.toString());

        yield self.whoAmI();

        self.loadingStatus.finish();
      } catch (err) {
        self.loadingStatus.fail(extractError(err));
      }
    }),
  }));

type AuthStoreType = typeof AuthModel;

interface AuthStoreTypeInterface extends AuthStoreType {}

export interface AuthStoreInterface extends MSTInstance<AuthStoreTypeInterface> {}

export const AuthStore: AuthStoreTypeInterface = AuthModel;
