import { Theme } from '@mui/material';

export function getMediaQueryForPhone(theme: Theme) {
  // 0 - 599
  return theme.breakpoints.down('sm');
}

export function getMediaQueryForTablet(theme: Theme) {
  // 600-1279.9
  return theme.breakpoints.between('sm', 'lg');
}

export function getMediaQueryForDesktop(theme: Theme) {
  // 1280+ (MUI lg)
  return theme.breakpoints.up('lg');
}
