import clsx from 'clsx';
import React, { SVGAttributes } from 'react';

import { LogoStyles } from '@bpm/components/Logo/Logo.styles';
import { Body } from '@bpm/ui-kit/components/Typography';
import { IconBpmLogo } from '@bpm/ui-kit/components/icons/iconComponents/IconBpmLogo';
import { usePhoneBreakpoint } from '@bpm/utils/hooks/theme';

export const Logo: React.FC<SVGAttributes<SVGElement>> = props => {
  const classes = LogoStyles();
  const isMobile = usePhoneBreakpoint();

  return (
    <div className={classes.root}>
      <IconBpmLogo {...props} className={classes.logo} />
      <Body
        className={clsx({
          [classes.beta]: true,
          [classes.betaSmall]: isMobile,
        })}
      >
        BETA
      </Body>
    </div>
  );
};
