import { createHttpClient } from 'mst-gql';

import { appConfig } from '@bpm/constants';
import { Api } from '@bpm/services/Api';

export class Environment {
  api: Api;
  gqlHttpClient;

  constructor() {
    this.api = new Api();
    this.gqlHttpClient = createHttpClient(`${appConfig.apiUrl}/graphql/`, { credentials: 'include' });
  }

  setToken(token: string) {
    this.api.token = token;
    this.gqlHttpClient.setHeader('Authorization', `Bearer ${token}`);
  }

  clearToken() {
    this.api.token = undefined;
    this.gqlHttpClient.setHeader('Authorization', '');
    localStorage.removeItem('token');
  }
}
