import React, { useState } from 'react';

import { FullScreenLoader } from '@bpm/components/FullScreenLoader/FullScreenLoader';
import { useStores } from '@bpm/mobx/useStores';
import useOnMount from '@bpm/utils/hooks/useOnMount';

import { AppStyles } from './App.styles';
import AppRouter from './routes';

const App: React.FC = () => {
  const classes = AppStyles();
  const [isReady, setIsReady] = useState(false);
  const { authStore } = useStores();

  useOnMount(() => {
    authStore.whoAmI().then(() => {
      setIsReady(true);
    });
  });

  if (!isReady) {
    return <FullScreenLoader />;
  }

  return (
    <div className={classes.root}>
      <AppRouter />
    </div>
  );
};

export default App;
