import { ClickAwayListener, Grid } from '@mui/material';
import React, { useState } from 'react';

import { Spacer } from '@bpm/components/Spacer/Spacer';
import { useTranslation } from '@bpm/i18n';
import { languageLabels, Languages, supportedLngs } from '@bpm/i18n/languages';
import { Button } from '@bpm/ui-kit/components/Button/Button';
import { Paper } from '@bpm/ui-kit/components/Paper/Paper';
import { Popper } from '@bpm/ui-kit/components/Popper';
import { Body } from '@bpm/ui-kit/components/Typography/Typography';
import { usePhoneBreakpoint } from '@bpm/utils/hooks/theme';

import { LanguageListProps, LanguageSwitcherProps } from './LanguageSwitcher.props';

const MobileLanguageList: React.FC<LanguageListProps> = props => {
  const { onSelect } = props;
  const { currentLanguage } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={3}>
      {supportedLngs.map((lng, idx) => (
        <Grid item key={`${lng}--${idx}`}>
          <Body
            onClick={() => onSelect(lng)}
            component="span"
            color={currentLanguage === lng ? 'philippineGray' : 'primary'}
          >
            {languageLabels[lng]}
          </Body>
        </Grid>
      ))}
    </Grid>
  );
};

const LanguageList: React.FC<LanguageListProps> = props => {
  const { onSelect } = props;

  return (
    <Paper bgcolor="primary" sx={{ padding: 2, margin: 2 }}>
      {supportedLngs.map((lng, idx) => (
        <Spacer key={lng} mb={idx !== supportedLngs.length - 1 ? 12 : 0}>
          <Body onClick={() => onSelect(lng)} cursor="pointer" color="white">
            {languageLabels[lng]}
          </Body>
        </Spacer>
      ))}
    </Paper>
  );
};

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = () => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { currentLanguage, changeLanguage } = useTranslation();
  const isMobile = usePhoneBreakpoint();

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsPopperOpen(!isPopperOpen);
    setAnchorEl(event.currentTarget);
  };

  const onClickAwayClick = () => {
    if (isPopperOpen) {
      setIsPopperOpen(false);
    }
  };

  const onLanguageSelect = (lng: string) => {
    if (currentLanguage === lng) {
      return;
    }
    changeLanguage(lng);
    onClickAwayClick();
  };

  if (isMobile) {
    return <MobileLanguageList onSelect={onLanguageSelect} />;
  }

  return (
    <>
      <Button onClick={onButtonClick} variant="outlined" size="small">
        {languageLabels[currentLanguage as Languages]}
      </Button>
      <Popper open={isPopperOpen} anchorEl={anchorEl} placement="bottom">
        <ClickAwayListener onClickAway={onClickAwayClick}>
          <div>
            <LanguageList onSelect={onLanguageSelect} />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

LanguageSwitcher.displayName = 'LanguageSwitcher';
