import MuiPopper from '@mui/material/Popper';
import React, { forwardRef } from 'react';

import { PopperProps } from './Popper.props';
import { PopperStyles } from './Popper.styles';

export const Popper: React.FC<PopperProps> = forwardRef<HTMLDivElement, PopperProps>((props, ref) => {
  const { children, ...rest } = props;
  const classes = PopperStyles();

  return (
    <>
      <MuiPopper ref={ref} className={classes.root} {...rest}>
        {children}
      </MuiPopper>
    </>
  );
});
