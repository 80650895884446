import { NavigateOptions } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from '@bpm/i18n';

function useNavigation() {
  const { localePath } = useTranslation();
  const navigate = useNavigate();

  const switchTo = (path: string, options?: NavigateOptions) => {
    navigate(localePath(path), options);
  };

  const goBack = () => {
    navigate(-1);
  };

  return { switchTo, goBack };
}

export default useNavigation;
