import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { FallbackElement } from '@bpm/components/FullScreenLoader/FullScreenLoader';
import { MemberTypes } from '@bpm/mobx/graphql';
import { PatientLayout } from '@bpm/pages/protected/components/PatientLayout/PatientLayout';
import { ProtectedRoute } from '@bpm/pages/protected/components/ProtectedRoute/ProtectedRoute';
import ForgotPassword from '@bpm/pages/public/forgot-password';
import Login from '@bpm/pages/public/login';
import ResetPassword from '@bpm/pages/public/reset-password';
import SignUp from '@bpm/pages/public/signup';
import { routeTable } from '@bpm/routes/constants';

const SigningLayout = React.lazy(() => import('../../pages/public/components/SigningLayout/SigningLayout'));
const Dashboard = React.lazy(() => import('../../pages/protected/dashboard'));
const AddPatient = React.lazy(() => import('../../pages/protected/add-patient'));
const Patients = React.lazy(() => import('../../pages/protected/patients-list'));
const ViewPatient = React.lazy(() => import('../../pages/protected/view-patient'));
const EditPatient = React.lazy(() => import('../../pages/protected/edit-patient'));
const PatientPrediction = React.lazy(() => import('../../pages/protected/patient-prediction'));
const InviteUser = React.lazy(() => import('../../pages/protected/invite-user'));

const NotFound = React.lazy(() => import('../../pages/public/not-found'));

const Router = React.memo(() => {
  return (
    <Routes>
      <Route
        element={
          <FallbackElement>
            <SigningLayout />
          </FallbackElement>
        }
      >
        <Route path={routeTable.login} element={<Login />} />
        <Route path={routeTable.signup} element={<SignUp />} />
        <Route path={routeTable.forgotPassword} element={<ForgotPassword />} />
        <Route path={routeTable.resetPassword} element={<ResetPassword />} />
      </Route>

      <Route element={<ProtectedRoute memberTypes={[MemberTypes.doctor]} />}>
        <Route
          path={routeTable.dashboard}
          element={
            <FallbackElement>
              <Dashboard />
            </FallbackElement>
          }
        />

        <Route
          path={routeTable.patients}
          element={
            <FallbackElement>
              <Patients />
            </FallbackElement>
          }
        />

        <Route element={<PatientLayout />}>
          <Route
            path={routeTable.viewPatient}
            element={
              <FallbackElement>
                <ViewPatient />
              </FallbackElement>
            }
          />

          <Route
            path={routeTable.editPatientById}
            element={
              <FallbackElement>
                <EditPatient />
              </FallbackElement>
            }
          />

          <Route
            path={routeTable.viewPatientPrediction}
            element={
              <FallbackElement>
                <PatientPrediction />
              </FallbackElement>
            }
          />
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route
          path={routeTable.addPatient}
          element={
            <FallbackElement>
              <AddPatient />
            </FallbackElement>
          }
        />

        <Route element={<PatientLayout />}>
          <Route
            path={routeTable.patient}
            element={
              <FallbackElement>
                <ViewPatient />
              </FallbackElement>
            }
          />

          <Route
            path={routeTable.editPatient}
            element={
              <FallbackElement>
                <EditPatient />
              </FallbackElement>
            }
          />

          <Route
            path={routeTable.patientPrediction}
            element={
              <FallbackElement>
                <PatientPrediction />
              </FallbackElement>
            }
          />
        </Route>

        <Route
          path={routeTable.inviteUser}
          element={
            <FallbackElement>
              <InviteUser />
            </FallbackElement>
          }
        />
      </Route>

      <Route path={routeTable.notFound} />
      <Route
        path="*"
        element={
          <FallbackElement>
            <NotFound />
          </FallbackElement>
        }
      />
    </Routes>
  );
});

export default Router;
