import { makeStyles } from '@mui/styles';

import { CardProps } from './Card.props';

export const CardStyles = makeStyles<{}, CardProps>({
  root: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});
