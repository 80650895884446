export const routeTable = {
  login: '/',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  dashboard: '/dashboard',
  patients: '/patients',
  addPatient: '/patients/add',

  viewPatient: '/patients/:id',
  editPatientById: '/patients/:id/edit',
  viewPatientPrediction: '/patients/:id/prediction',

  patient: '/patient',
  editPatient: '/patient/edit',
  patientPrediction: '/patient/prediction',

  inviteUser: '/invite-user',
  notFound: '/not-found',
};

export const externalLinks = {
  privacyPolicy: 'https://www.biterium.ge/LOCALE/privacy-policy',
};
