import { Instance } from 'mobx-state-tree';

import { PatientModelBase } from './PatientModel.base';

/* The TypeScript type of an instance of PatientModel */
export interface PatientModelType extends Instance<typeof PatientModel.Type> {}

/* A graphql query fragment builders for PatientModel */
export { selectFromPatient, patientModelPrimitives, PatientModelSelector } from './PatientModel.base';

/**
 * PatientModel
 */
export const PatientModel = PatientModelBase.actions(self => ({})).views(self => ({
  get fullName(): string {
    return `${self.firstName} ${self.lastName}`;
  },
  get hasEnoughPredictionData(): boolean {
    return self.timeseries.length >= 10;
  },
}));
