import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getMediaQueryForPhone } from '@bpm/utils/MediaQuery';

import { colors } from '../../theme';
import { bodyFontFamily } from '../Typography/Typography.styles';

import { InputProps } from './Input.props';

export const InputStyles = makeStyles<Theme, InputProps>(theme => {
  const mobile = getMediaQueryForPhone(theme);

  return {
    root: ({ secondary }) => ({
      padding: '9px 18px',
      borderRadius: 12,
      border: `1px solid ${secondary ? colors.jaggedIce : colors.easterBlue}`,
      backgroundColor: secondary ? colors.jaggedIce : colors.white,
      fontSize: 16,
      fontFamily: bodyFontFamily,
      transition: '0.2s border ease-in-out',
      '&::before, &::after': {
        border: 0,
        display: 'none',
      },
      '&:focus-within': {
        border: `1px solid ${secondary ? colors.easterBlue : colors.black}`,
      },
      'label+&': {
        marginTop: 0,
      },
      [mobile]: {
        fontSize: 14,
      },
    }),
    focused: ({ secondary }) => ({
      border: `1px solid ${secondary ? colors.easterBlue : colors.black}`,
    }),
    error: () => ({
      border: `1px solid ${colors.black}`,
    }),
    input: {
      padding: 0,
      '&::placeholder': {
        color: colors.black,
        opacity: 1,
      },
      [mobile]: {
        fontSize: 14,
      },
    },
    iconButtonRoot: {
      padding: 0,
      [mobile]: {
        width: 20,
        height: 20,
        padding: 2,
      },
    },
    helperText: {
      fontSize: 12,
      margin: '2px 19px 0',
    },
  };
});

export const InputLabelStyles = makeStyles<{}, InputProps>({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '100%',
    overflow: 'visible',
    padding: '0 4px',
    transform: 'translate(0, 0)',
    marginLeft: 14,
    color: colors.black,
    transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '&.Mui-focused': {
      color: colors.black,
    },
  },
  shrink: {
    height: '13px',
    transform: 'translate(0, -7px)',
    fontSize: 13,
    backgroundColor: colors.white,
    color: colors.easterBlue,
  },
});
