import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';

import useOnMount from '@bpm/utils/hooks/useOnMount';

import { useTranslation } from '../i18n';

import AppRouter from './app';

const AppRootRouter: React.FC = () => {
  const { locale } = useParams();
  const { currentLanguage, changeLanguage } = useTranslation();

  useOnMount(() => {
    if (locale && locale !== currentLanguage) {
      changeLanguage(locale);
    }
  });

  return <AppRouter />;
};

const Router = React.memo(() => {
  const { currentLanguage } = useTranslation();

  return (
    <Routes>
      <Route path="/:locale/*" element={<AppRootRouter />} />
      <Route path="*" element={<Navigate to={`/${currentLanguage}`} replace />} />
    </Routes>
  );
});

export default Router;
