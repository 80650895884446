import { Formik, Form } from 'formik';
import React from 'react';

import { Spacer } from '@bpm/components/Spacer/Spacer';
import { useTranslation } from '@bpm/i18n';
import { useStores } from '@bpm/mobx/useStores';
import { routeTable } from '@bpm/routes/constants';
import { Button } from '@bpm/ui-kit/components/Button/Button';
import { Input } from '@bpm/ui-kit/components/Input/Input';
import { Heading, Body } from '@bpm/ui-kit/components/Typography/Typography';
import useNavigation from '@bpm/utils/hooks/useNavigation';

import { FormikHelpers } from 'formik/dist/types';

import { formInitialValues, validationSchema } from './index.formik';
import { FormFieldsProps } from './index.props';

const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation('signing');
  const { authStore } = useStores();
  const { switchTo } = useNavigation();

  const { forgotPassword, loadingStatus } = authStore;

  const submitForm = async (values: FormFieldsProps, formikHelpers: FormikHelpers<FormFieldsProps>) => {
    await forgotPassword(values.email);

    if (loadingStatus.isSuccess) {
      formikHelpers.resetForm();
    }
  };

  const handleCancel = () => {
    switchTo(routeTable.login);
  };

  return (
    <Formik initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={submitForm}>
      {form => (
        <Form onSubmit={form.handleSubmit} noValidate>
          <Heading>{t('forgotPassword')}</Heading>

          <Spacer mt={50} mb={40}>
            <Input
              fullWidth
              autoFocus
              type="email"
              name="email"
              value={form.values.email}
              onChange={form.handleChange}
              placeholder={t('email')}
              error={form.touched.email && !!form.errors.email}
              helperText={form.touched.email && form.errors.email}
            />
          </Spacer>

          <Spacer my={20}>
            <Button fullWidth type="submit" disabled={form.isSubmitting}>
              {t('forgotPassword')}
            </Button>
          </Spacer>

          <Spacer my={20}>
            <Button fullWidth variant="outlined" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </Spacer>

          {loadingStatus.isSuccess && (
            <Body isBold color="persianGreen">
              {t('forgotPasswordSuccess')}
            </Body>
          )}

          {loadingStatus.isError && (
            <Body isBold color="lightCoral">
              {loadingStatus.error}
            </Body>
          )}
        </Form>
      )}
    </Formik>
  );
};

const ForgotPassword: React.FC = () => <ForgotPasswordForm />;

export default ForgotPassword;
