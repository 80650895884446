import { ApisauceInstance, create } from 'apisauce';

import { appConfig } from '@bpm/constants';
import { stringifyQueryParams } from '@bpm/utils/url';

export class Api {
  public client: ApisauceInstance;
  public token?: string;

  constructor() {
    this.client = create({
      baseURL: appConfig.apiUrl,
      paramsSerializer: params => stringifyQueryParams(params),
    });

    this.client.addRequestTransform(request => {
      if (this.token) {
        request.headers.Authorization = `Bearer ${this.token}`;
      }
    });
  }
}
