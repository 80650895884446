/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { QueryBuilder } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { RootStoreType } from './index';

/**
 * StatisticsChartOutputBase
 * auto generated base class for the model StatisticsChartOutputModel.
 */
export const StatisticsChartOutputModelBase = ModelBase.named('StatisticsChartOutput')
  .props({
    __typename: types.optional(types.literal('StatisticsChartOutput'), 'StatisticsChartOutput'),
    xDiastolic: types.union(types.undefined, types.array(types.number)),
    xSystolic: types.union(types.undefined, types.array(types.number)),
    yCardio: types.union(types.undefined, types.array(types.number)),
    yDiastolic: types.union(types.undefined, types.array(types.number)),
    ySystolic: types.union(types.undefined, types.array(types.number)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>();
    },
  }));

export class StatisticsChartOutputModelSelector extends QueryBuilder {
  get xDiastolic() {
    return this.__attr(`xDiastolic`);
  }
  get xSystolic() {
    return this.__attr(`xSystolic`);
  }
  get yCardio() {
    return this.__attr(`yCardio`);
  }
  get yDiastolic() {
    return this.__attr(`yDiastolic`);
  }
  get ySystolic() {
    return this.__attr(`ySystolic`);
  }
}
export function selectFromStatisticsChartOutput() {
  return new StatisticsChartOutputModelSelector();
}

export const statisticsChartOutputModelPrimitives =
  selectFromStatisticsChartOutput().xDiastolic.xSystolic.yCardio.yDiastolic.ySystolic;
