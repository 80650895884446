import { getEnv, IStateTreeNode } from 'mobx-state-tree';

import { Environment } from '../Environment';

export const withEnvironment = (self: IStateTreeNode) => ({
  views: {
    get env() {
      return getEnv<Environment>(self);
    },
    get environment() {
      return getEnv<Environment>(self);
    },
  },
});
