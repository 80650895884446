import { makeStyles } from '@mui/styles';

import { colors } from '../../theme';
import { createShadow } from '../../theme/shadows';

import { PaperProps } from './Paper.props';

export const PaperStyles = makeStyles<{}, PaperProps>({
  root: ({ shadowColor, elevation, bgcolor = 'white' }) => ({
    borderRadius: 12,
    boxShadow: createShadow(elevation, shadowColor),
    backgroundColor: colors[bgcolor],
  }),
});
