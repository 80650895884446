import MuiDivider from '@mui/material/Divider/Divider';
import React from 'react';

import { DividerStyles } from '@bpm/ui-kit/components/Divider/Divider.styles';

import { DividerProps } from './Divider.props';

export const Divider: React.FC<DividerProps> = props => {
  const classes = DividerStyles(props);
  return <MuiDivider classes={classes} {...props} />;
};
