import clsx from 'clsx';
import React, { forwardRef, memo } from 'react';

import { TypographyProps } from './Typography.props';
import { TypographyStyles } from './Typography.styles';

// forwardRef for wrappers like Tooltip work with this custom component, see https://mui.com/components/tooltips/#custom-child-element
const Typography = memo(
  forwardRef<HTMLAnchorElement, TypographyProps>((props, ref) => {
    const { component: Component = 'div', textVariant = 'body', className, children, isBold, ...rest } = props;
    const classes = TypographyStyles(props);

    return (
      <Component className={clsx(classes.root, classes[textVariant], className)} ref={ref} {...rest}>
        {children}
      </Component>
    );
  }),
);

export const Hero: React.FC<TypographyProps> = props => <Typography {...props} textVariant="hero" isBold />;

export const Heading: React.FC<TypographyProps> = props => <Typography {...props} textVariant="heading" isBold />;

export const Body: React.FC<TypographyProps> = props => <Typography {...props} textVariant="body" />;

export const BodySmall: React.FC<TypographyProps> = props => <Typography {...props} textVariant="bodySmall" />;
