/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { QueryBuilder } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { RootStoreType } from './index';

/**
 * CardioPieChartOutputBase
 * auto generated base class for the model CardioPieChartOutputModel.
 */
export const CardioPieChartOutputModelBase = ModelBase.named('CardioPieChartOutput')
  .props({
    __typename: types.optional(types.literal('CardioPieChartOutput'), 'CardioPieChartOutput'),
    label: types.union(types.undefined, types.string),
    number: types.union(types.undefined, types.number),
    percent: types.union(types.undefined, types.number),
    type: types.union(types.undefined, types.number),
    yposition: types.union(types.undefined, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>();
    },
  }));

export class CardioPieChartOutputModelSelector extends QueryBuilder {
  get label() {
    return this.__attr(`label`);
  }
  get number() {
    return this.__attr(`number`);
  }
  get percent() {
    return this.__attr(`percent`);
  }
  get type() {
    return this.__attr(`type`);
  }
  get yposition() {
    return this.__attr(`yposition`);
  }
}
export function selectFromCardioPieChartOutput() {
  return new CardioPieChartOutputModelSelector();
}

export const cardioPieChartOutputModelPrimitives = selectFromCardioPieChartOutput().label.number.percent.type.yposition;
