/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { QueryBuilder } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { RootStoreType } from './index';

/**
 * ResetPasswordOutputBase
 * auto generated base class for the model ResetPasswordOutputModel.
 */
export const ResetPasswordOutputModelBase = ModelBase.named('ResetPasswordOutput')
  .props({
    __typename: types.optional(types.literal('ResetPasswordOutput'), 'ResetPasswordOutput'),
    token: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>();
    },
  }));

export class ResetPasswordOutputModelSelector extends QueryBuilder {
  get token() {
    return this.__attr(`token`);
  }
}
export function selectFromResetPasswordOutput() {
  return new ResetPasswordOutputModelSelector();
}

export const resetPasswordOutputModelPrimitives = selectFromResetPasswordOutput().token;
