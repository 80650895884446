import { types } from 'mobx-state-tree';
import { createContext } from 'react';

import { ChartsStore } from '@bpm/mobx/stores/ChartsStore/Charts.store';
import { PatientsStore } from '@bpm/mobx/stores/PatientsStore/Patients.store';

import { RootStore as GraphqlStore } from './graphql';
import { AuthStore } from './stores/AuthStore/Auth.store';
import { MSTInstance, MSTModelInstanceRecord } from './types/mst';

const Stores = {
  authStore: types.late(() => types.optional(AuthStore, {})),
  patientsStore: types.late(() => types.optional(PatientsStore, {})),
  chartsStore: types.late(() => types.optional(ChartsStore, {})),

  graphql: types.late(() => types.optional(GraphqlStore, {})),
};

export type RootStoreSubstores = typeof Stores;

const Store = types.model('RootStore', Stores).actions(self => ({}));

type StoreType = typeof Store;
interface StoreTypeInterface extends StoreType {}
export interface RootStoreInterface extends MSTInstance<StoreTypeInterface> {}
export const RootStore: StoreTypeInterface = Store;

export type RootStoreShape = MSTModelInstanceRecord<RootStoreSubstores>;

export const RootStoreContext = createContext<null | RootStoreInterface>(null);
export const StoreProvider = RootStoreContext.Provider;
