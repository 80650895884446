import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { SimpleLink } from '@bpm/components/SimpleLink/SimpleLink';
import { Spacer } from '@bpm/components/Spacer/Spacer';
import { useTranslation } from '@bpm/i18n';
import { useStores } from '@bpm/mobx/useStores';
import { externalLinks, routeTable } from '@bpm/routes/constants';
import { Button } from '@bpm/ui-kit/components/Button/Button';
import { Checkbox } from '@bpm/ui-kit/components/Checkbox/Checkbox';
import { CheckboxHelperText } from '@bpm/ui-kit/components/CheckboxHelperText';
import { FormControlLabel } from '@bpm/ui-kit/components/FormControlLabel';
import { Input } from '@bpm/ui-kit/components/Input/Input';
import { Body, Heading } from '@bpm/ui-kit/components/Typography/Typography';
import { IconEyeHideStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconEyeHideStandard';
import { IconEyeVewStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconEyeVewStandard';
import { colors } from '@bpm/ui-kit/theme';
import useNavigation from '@bpm/utils/hooks/useNavigation';

import { FormFieldsProps } from './index.props';

const formInitialValues: FormFieldsProps = {
  name: '',
  email: '',
  password: '',
  repeatPassword: '',
  company: '',
  isDoctor: false,
  hasAcceptedTerms: false,
};

const SignUpForm: React.FC = observer(() => {
  const { t, currentLanguage } = useTranslation(['signing']);
  const { t: validationsT } = useTranslation('validations');
  const [showPassword, setShowPassword] = useState(false);
  const [registrationError, setRegistrationError] = useState('');

  const [searchParams] = useSearchParams();
  const { switchTo } = useNavigation();
  const { authStore } = useStores();
  const { registerUser } = authStore;

  const IconEyeView = <IconEyeVewStandard color={colors.easterBlue} />;
  const IconEyeHide = <IconEyeHideStandard color={colors.easterBlue} />;

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(validationsT('auth.emailInvalid')).required(validationsT('auth.emailRequired')),
    password: Yup.string().min(6, validationsT('auth.passwordLength')).required(validationsT('auth.passwordRequired')),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('password')], validationsT('auth.passwordNotMatch'))
      .required(validationsT('auth.repeatPasswordRequired')),
    isDoctor: Yup.boolean(),
    company: Yup.string().when('isDoctor', {
      is: (isDoctor: boolean) => isDoctor,
      then: Yup.string().required(validationsT('auth.doctorRequired')),
      otherwise: Yup.string().notRequired(),
    }),
    hasAcceptedTerms: Yup.boolean()
      .required(validationsT('auth.termsRequired'))
      .oneOf([true], validationsT('auth.termsRequired')),
  });

  const privacyPolicyUrl = externalLinks.privacyPolicy.replace('LOCALE', currentLanguage);

  const onFormSubmit = ({ name, email, password, repeatPassword, company }: FormFieldsProps) => {
    return registerUser({
      name,
      email,
      password,
      repeatPassword,
      company,
    })
      .then(() => {
        switchTo(routeTable.dashboard);
      })
      .catch(() => {
        setRegistrationError('somethingWentWrong');
      });
  };

  return (
    <>
      <Heading>{t('signUp')}</Heading>
      <Formik
        initialValues={{ ...formInitialValues, email: searchParams.get('email') ?? '' }}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        {form => (
          <Form onSubmit={form.handleSubmit} noValidate>
            <Spacer mt={50} mb={20}>
              <Input
                fullWidth
                autoFocus
                type="text"
                name="name"
                value={form.values.name}
                onChange={form.handleChange}
                placeholder={t('name')}
                error={form.touched.name && !!form.errors.name}
                helperText={form.touched.name && form.errors.name}
              />
            </Spacer>
            <Spacer my={20}>
              <Input
                fullWidth
                autoFocus
                type="email"
                name="email"
                value={form.values.email}
                onChange={form.handleChange}
                placeholder={t('email')}
                error={form.touched.email && !!form.errors.email}
                helperText={form.touched.email && form.errors.email}
              />
            </Spacer>
            <Spacer my={20}>
              <Input
                fullWidth
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={form.values.password}
                onChange={form.handleChange}
                placeholder={t('password')}
                onAdornmentClick={() => setShowPassword(!showPassword)}
                endAdornment={showPassword ? IconEyeView : IconEyeHide}
                error={form.touched.password && !!form.errors.password}
                helperText={form.touched.password && form.errors.password}
              />
            </Spacer>
            <Spacer my={20}>
              <Input
                fullWidth
                type={showPassword ? 'text' : 'password'}
                name="repeatPassword"
                value={form.values.repeatPassword}
                onChange={form.handleChange}
                placeholder={t('repeatPassword')}
                onAdornmentClick={() => setShowPassword(!showPassword)}
                endAdornment={showPassword ? IconEyeView : IconEyeHide}
                error={form.touched.repeatPassword && !!form.errors.repeatPassword}
                helperText={form.touched.repeatPassword && form.errors.repeatPassword}
              />
            </Spacer>
            <Spacer mt={20} mb={10}>
              <FormControlLabel
                name="isDoctor"
                checked={form.values.isDoctor}
                onChange={form.handleChange}
                control={<Checkbox />}
                label={t('areYouDoctor') as string}
              />
            </Spacer>
            {form.values.isDoctor && (
              <Spacer mb={20}>
                <Input
                  fullWidth
                  type="text"
                  name="company"
                  value={form.values.company}
                  onChange={form.handleChange}
                  placeholder={t('company')}
                  error={form.touched.company && !!form.errors.company}
                  helperText={form.touched.company && form.errors.company}
                />
              </Spacer>
            )}
            <FormControlLabel
              name="hasAcceptedTerms"
              checked={form.values.hasAcceptedTerms}
              onChange={form.handleChange}
              control={<Checkbox />}
              label={
                <Grid container>
                  <Body>{t('agree')}&nbsp;</Body>
                  <SimpleLink href={privacyPolicyUrl} target="_blank">
                    <Body isBold>{t('terms')}</Body>
                  </SimpleLink>
                </Grid>
              }
            />
            <CheckboxHelperText error={form.touched.hasAcceptedTerms && !!form.errors.hasAcceptedTerms}>
              {form.touched.hasAcceptedTerms && form.errors.hasAcceptedTerms}
            </CheckboxHelperText>

            <Spacer my={20}>
              <Button fullWidth type="submit" disabled={form.isSubmitting}>
                {t('signUp')}
              </Button>
            </Spacer>

            {registrationError && (
              <Body isBold color="lightCoral">
                {t(registrationError, { ns: 'errors' })}
              </Body>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
});

const SignUp: React.FC = () => <SignUpForm />;

export default SignUp;
