import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { MemberTypes } from '@bpm/mobx/graphql';
import { useStores } from '@bpm/mobx/useStores';

import { DashboardLayout } from '../DashboardLayout/DashboardLayout';

import { ProtectedRouteProps } from './ProtectedRoute.props';

export const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(props => {
  const { children, memberTypes = [MemberTypes.patient, MemberTypes.doctor] } = props;
  const { authStore } = useStores();
  const { user } = authStore;

  const userHasAccessToRoute = user && memberTypes.includes(user.memberType);

  if (!user || !userHasAccessToRoute) {
    return <Navigate to="/" replace />;
  }

  return children ? (
    <>{children}</>
  ) : (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
});
