export enum Languages {
  EN = 'en',
  KA = 'ka',
}

export const supportedLngs = [Languages.EN, Languages.KA];
export const languageLabels = {
  [Languages.EN]: 'English',
  [Languages.KA]: 'ქართული',
};
