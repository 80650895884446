/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { QueryBuilder } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { RootStoreType } from './index';

/**
 * CardioLosesChartOutputBase
 * auto generated base class for the model CardioLosesChartOutputModel.
 */
export const CardioLosesChartOutputModelBase = ModelBase.named('CardioLosesChartOutput')
  .props({
    __typename: types.optional(types.literal('CardioLosesChartOutput'), 'CardioLosesChartOutput'),
    xBreak: types.union(types.undefined, types.array(types.number)),
    xLabel: types.union(types.undefined, types.array(types.string)),
    xSysDias: types.union(types.undefined, types.array(types.number)),
    yCardio: types.union(types.undefined, types.array(types.number)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>();
    },
  }));

export class CardioLosesChartOutputModelSelector extends QueryBuilder {
  get xBreak() {
    return this.__attr(`xBreak`);
  }
  get xLabel() {
    return this.__attr(`xLabel`);
  }
  get xSysDias() {
    return this.__attr(`xSysDias`);
  }
  get yCardio() {
    return this.__attr(`yCardio`);
  }
}
export function selectFromCardioLosesChartOutput() {
  return new CardioLosesChartOutputModelSelector();
}

export const cardioLosesChartOutputModelPrimitives = selectFromCardioLosesChartOutput().xBreak.xLabel.xSysDias.yCardio;
