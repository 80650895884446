import MuiDrawer from '@mui/material/Drawer';
import React from 'react';

import { DrawerProps } from './Drawer.props';
import { DrawerStyles } from './Drawer.styles';

export const Drawer: React.FC<DrawerProps> = props => {
  const { fullScreen, ...rest } = props;
  const classes = DrawerStyles(props);

  return <MuiDrawer classes={classes} {...rest} />;
};
