import { Grid } from '@mui/material';
import React from 'react';

import { LanguageSwitcher } from '@bpm/components/LanguageSwitcher/LanguageSwitcher';
import { Spacer } from '@bpm/components/Spacer/Spacer';
import { useTranslation } from '@bpm/i18n';
import { useStores } from '@bpm/mobx/useStores';
import { routeTable } from '@bpm/routes/constants';
import { Card } from '@bpm/ui-kit/components/Card/Card';
import { Divider } from '@bpm/ui-kit/components/Divider';
import { Body } from '@bpm/ui-kit/components/Typography/Typography';
import { IconExitStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconExitStandard';
import { IconUserStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconUserStandard';
import { usePhoneBreakpoint } from '@bpm/utils/hooks/theme';
import useNavigation from '@bpm/utils/hooks/useNavigation';

import { MenuItemProps, PopperContentProps } from './DashboardLayout.props';
import { DashboardLayoutStyles, PopperContentStyles } from './DashboardLayout.styles';

const PopperMenuItem: React.FC<MenuItemProps> = props => {
  const { icon, text, onClick } = props;
  const isMobile = usePhoneBreakpoint();
  const textColor = isMobile ? 'black' : 'white';

  return (
    <Grid container item alignItems="center" onClick={() => onClick()}>
      {icon}
      <Spacer ml={12}>
        <Body color={textColor} cursor="pointer">
          {text}
        </Body>
      </Spacer>
    </Grid>
  );
};

export const HeaderMenuContent: React.FC<PopperContentProps> = props => {
  const classes = PopperContentStyles();
  const dashboardLayoutClasses = DashboardLayoutStyles();

  const { onClose } = props;
  const { authStore } = useStores();
  const { switchTo } = useNavigation();
  const { t } = useTranslation('menuItems');
  const { user } = authStore;
  const isMobile = usePhoneBreakpoint();
  const textColor = isMobile ? 'black' : 'white';

  if (!user) {
    return null;
  }

  const onLogoutClick = () => {
    authStore.logout();
    switchTo(routeTable.login);
  };

  const switchAndClosePopper = (route: string) => {
    switchTo(route);
    onClose();
  };

  return (
    <Card className={classes.root}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Body color={textColor} isBold>
            {user.email}
          </Body>
        </Grid>
        <Grid item>
          <Spacer mt={8}>
            <Body color={textColor}>{user.isDoctor ? t('doctor') : t('patient')}</Body>
          </Spacer>
        </Grid>
      </Grid>

      <Spacer my={16}>
        <Divider orientation="horizontal" color={isMobile ? 'jaggedIce' : 'white'} />
      </Spacer>

      <PopperMenuItem
        icon={<IconUserStandard color={textColor} className={dashboardLayoutClasses.svgIcon} />}
        text={t('inviteUser')}
        onClick={() => switchAndClosePopper(routeTable.inviteUser)}
        active={null}
      />
      <Spacer mt={20} />
      <PopperMenuItem
        icon={<IconExitStandard color={textColor} className={dashboardLayoutClasses.svgIcon} />}
        text={t('logout')}
        onClick={onLogoutClick}
        active={null}
      />
      {isMobile && (
        <div className={classes.languageSwitcher}>
          <LanguageSwitcher />
        </div>
      )}
    </Card>
  );
};
