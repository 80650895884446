import { CircularProgress } from '@mui/material';
import React from 'react';

import { LoaderProps } from './Loader.props';
import { LoaderStyles } from './Loader.styles';

export const Loader: React.FC<LoaderProps> = props => {
  const { ...rest } = props;
  const classes = LoaderStyles(props);

  return (
    <span className={classes.root}>
      <CircularProgress {...rest} color="inherit" />
    </span>
  );
};
