import { flow, getRoot, types } from 'mobx-state-tree';

import { RootStoreShape } from '@bpm/mobx/RootStore';
import { withGraphql } from '@bpm/mobx/extensions/withGraphql';
import { PatientModel } from '@bpm/mobx/graphql';
import { CreatePatientInput, CreateTimeserieInput, UpdatePatientInput } from '@bpm/mobx/graphql/RootStore.base';
import { LoadingStatus } from '@bpm/mobx/models/LoadingStatus';
import { MSTInstance } from '@bpm/mobx/types/mst';

export enum Gender {
  male = 'male',
  female = 'female',
}

export enum RiskLevels {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export const possibleRiskLevels = {
  1: RiskLevels.low,
  2: RiskLevels.medium,
  3: RiskLevels.high,
};

type PossibleRiskLevelsType = keyof typeof possibleRiskLevels;

export const genderList = [Gender.male, Gender.female];

const PatientsModel = types
  .model('PatientsStore', {
    patients: types.maybe(types.array(types.safeReference(PatientModel))),
    patient: types.maybe(types.safeReference(PatientModel)),
    patientCardioRiskLevel: types.maybe(types.number),
    loadingStatus: types.optional(LoadingStatus, {}),
  })
  .extend(withGraphql)
  .views(self => ({
    get patientCardioRiskLevelLabel(): string | undefined {
      return self.patientCardioRiskLevel
        ? possibleRiskLevels[self.patientCardioRiskLevel as unknown as PossibleRiskLevelsType]
        : undefined;
    },
  }))
  .actions(self => ({
    loadPatientsList: flow(function* () {
      const data = yield self.graphql.queryPatients(
        {},
        patient =>
          patient.age.firstName.gender.habitsAndComplaints.height.lastName.presentDiseases.temperature.weight.updatedAt,
        { fetchPolicy: 'no-cache' },
      ).promise;

      if (!data) {
        return;
      }

      self.patients = data.patients;
    }),
    addPatient: flow(function* (createPatientInput: CreatePatientInput) {
      const { user } = getRoot<RootStoreShape>(self).authStore;

      if (user!.isPatient) {
        createPatientInput.userId = Number(user!.id);
      }

      return yield self.graphql.mutateCreatePatient({ createPatientInput }).promise;
    }),
    loadPatient: flow(function* (id: number) {
      self.patient = undefined;
      const data = yield self.graphql.queryPatient({ id }, patient =>
        patient.age.cholesterol.firstName.gender.glucose.habitsAndComplaints.height.hereditaryPressure.lastName.pregnancy.prescriptionDrugs.presentDiseases.currentTreatments.sideEffects.symptoms.temperature.weight.comment.timeseries(
          timeserie =>
            timeserie.measureDate.activity.systolic.diastolic.weakness.headAche.stress.healthyDiet.arrhythmia.comment,
        ),
      ).promise;

      if (!data) {
        return;
      }

      self.patient = data.patient;
    }),
    updatePatient: flow(function* (updatePatientInput: UpdatePatientInput) {
      const data = yield self.graphql.mutateUpdatePatient({ updatePatientInput }).promise;

      self.patient = data.updatePatient;
      return data;
    }),
    addTimeSerie: flow(function* (createTimeserieInput: CreateTimeserieInput) {
      return yield self.graphql.mutateCreateTimeserie({ createTimeserieInput }).promise;
    }),
    removeTimeSerie: flow(function* (id: number) {
      return yield self.graphql.mutateRemoveTimeserie({ id }).promise;
    }),
    getRiskLevel: flow(function* () {
      if (!self.patient) {
        return;
      }

      const predictionChartInput = {
        patientId: Number(self.patient.id),
        timeserieSize: self.patient.timeseries.length,
      };
      const data = yield self.graphql.queryRisklevel({ predictionChartInput }).promise;
      self.patientCardioRiskLevel = data.risklevel.patientCardioRiskLevel;
    }),
  }));

type PatientsStoreType = typeof PatientsModel;

interface PatientsStoreTypeInterface extends PatientsStoreType {}

export interface PatientsStoreInterface extends MSTInstance<PatientsStoreTypeInterface> {}

export const PatientsStore: PatientsStoreTypeInterface = PatientsModel;
