import { Instance } from 'mobx-state-tree';

import { UserModelBase } from './UserModel.base';

/* The TypeScript type of an instance of UserModel */
export interface UserModelType extends Instance<typeof UserModel.Type> {}

/* A graphql query fragment builders for UserModel */
export { selectFromUser, userModelPrimitives, UserModelSelector } from './UserModel.base';

export enum MemberTypes {
  doctor = 'doctor',
  patient = 'patient',
}

export type MemberPossibleType = keyof typeof MemberTypes;

/**
 * UserModel
 */
export const UserModel = UserModelBase.views(self => ({
  get initials(): string {
    return (self.email || '').charAt(0).toUpperCase();
  },
  get isDoctor(): boolean {
    return Boolean(self.doctor);
  },
  get isPatient(): boolean {
    return !self.doctor;
  },
  get memberType(): MemberPossibleType {
    return this.isDoctor ? MemberTypes.doctor : MemberTypes.patient;
  },
})).actions(self => ({}));
