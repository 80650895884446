import MuiPaper from '@mui/material/Paper/Paper';
import React from 'react';

import { PaperProps } from './Paper.props';
import { PaperStyles } from './Paper.styles';

export const Paper: React.FC<PaperProps> = props => {
  const { shadowColor, ...rest } = props;
  const classes = PaperStyles(props);

  return <MuiPaper classes={classes} {...rest} />;
};
