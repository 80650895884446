import { makeStyles } from '@mui/styles';

import { LoaderProps } from '@bpm/ui-kit/components/Loader/Loader.props';

import { colors } from '../../theme';

export const LoaderStyles = makeStyles<{}, LoaderProps>({
  root: ({ color = 'primary' }) => ({
    color: colors[color],
  }),
});
