import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AppLinkProps } from '@bpm/components/AppLink/AppLink.props';
import { colors } from '@bpm/ui-kit/theme';
import { getMediaQueryForPhone } from '@bpm/utils/MediaQuery';

export const AppLinkStyles = makeStyles<Theme, AppLinkProps>(theme => {
  const mobile = getMediaQueryForPhone(theme);

  return {
    root: {
      textDecoration: 'none',
      fontSize: 16,
      color: ({ color = 'primary' }) => colors[color],
      '&:hover': {
        textDecoration: 'underline',
      },
      [mobile]: {
        fontSize: 14,
      },
    },
  };
});
