const baseColors = {
  black: '#000000',
  white: '#ffffff',
  doveGray: '#696969',
  philippineGray: '#8d8d8d',
  softPeach: '#f9f4f5',
  palePink: '#fcdad6',
  roseBud: '#fcb3ab',
  lightCoral: '#f48678',
  terracotta: '#e36e5f',
  sunsetOrange: '#fd4e37',
  jaggedIce: '#ceeaeb',
  morningGlory: '#98d9db',
  easterBlue: '#2aa9b2',
  hawkesBlue: '#e3f1fd',
  persianGreen: '#01919a',
  cinderella: '#facec8',
  moonRaker: '#e1d7f7',
  perfume: '#d0bdfa',
  coldPurple: '#b19ddd',
  yellow: '#fffdb7',
  grey: '#757575',
  blue: '#1877f2',
};

const themeColors = {
  primary: baseColors.lightCoral,
  primaryHover: baseColors.terracotta,
  secondary: baseColors.easterBlue,
  secondaryHover: baseColors.persianGreen,
};

export const colors = {
  ...baseColors,
  ...themeColors,
};
