import { Grid, IconButton } from '@mui/material';
import React from 'react';

import { Spacer } from '@bpm/components/Spacer/Spacer';
import { useTranslation } from '@bpm/i18n';
import { Button } from '@bpm/ui-kit/components/Button/Button';
import { ButtonProps } from '@bpm/ui-kit/components/Button/Button.props';
import { Paper } from '@bpm/ui-kit/components/Paper/Paper';
import { Body } from '@bpm/ui-kit/components/Typography/Typography';
import { IconClosePopupStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconClosePopupStandard';
import { IconDownloadStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconDownloadStandard';
import { IconUploadStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconUploadStandard';
import { colors } from '@bpm/ui-kit/theme';

import { PopperContentProps } from './DashboardLayout.props';

const ButtonTertiary: React.FC<ButtonProps> = props => (
  <Button style={{ backgroundColor: colors.white, color: colors.black }} {...props} />
);

export const ImportDataContent: React.FC<PopperContentProps> = props => {
  const { onClose } = props;
  const { t } = useTranslation('importData');

  return (
    <Paper bgcolor="primary" sx={{ padding: 1 }}>
      <Grid container justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <IconClosePopupStandard color="white" />
        </IconButton>
      </Grid>
      <Spacer px={16} pt={16} pb={36}>
        <Grid container direction="column">
          <Button endIcon={<IconDownloadStandard />}>{t('downloadTemplate')}</Button>
          <Spacer mb={12} />
          <ButtonTertiary endIcon={<IconUploadStandard />}>{t('uploadFile')}</ButtonTertiary>
        </Grid>
        <Spacer mt={24} mb={8}>
          <Body isBold color="white" align="center">
            {t('success')}
          </Body>
        </Spacer>
        <Body color="white" align="center">
          {t('seeResults')}
        </Body>
      </Spacer>
    </Paper>
  );
};
