import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DrawerProps } from './Drawer.props';

export const DrawerStyles = makeStyles<Theme, DrawerProps>({
  paper: ({ fullScreen }) => ({
    width: fullScreen ? '100%' : 'auto',
  }),
});
