/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { UserModel, UserModelType } from './UserModel';
import { UserModelSelector } from './UserModel.base';
import { RootStoreType } from './index';

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  user: UserModelType;
};

/**
 * DoctorBase
 * auto generated base class for the model DoctorModel.
 */
export const DoctorModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Doctor')
    .props({
      __typename: types.optional(types.literal('Doctor'), 'Doctor'),
      company: types.union(types.undefined, types.string),
      createdAt: types.union(types.undefined, types.frozen()),
      id: types.identifier,
      updatedAt: types.union(types.undefined, types.frozen()),
      user: types.union(types.undefined, MSTGQLRef(types.late((): any => UserModel))),
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>();
      },
    })),
);

export class DoctorModelSelector extends QueryBuilder {
  get company() {
    return this.__attr(`company`);
  }
  get createdAt() {
    return this.__attr(`createdAt`);
  }
  get id() {
    return this.__attr(`id`);
  }
  get updatedAt() {
    return this.__attr(`updatedAt`);
  }
  user(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) {
    return this.__child(`user`, UserModelSelector, builder);
  }
}
export function selectFromDoctor() {
  return new DoctorModelSelector();
}

export const doctorModelPrimitives = selectFromDoctor().company.createdAt.updatedAt;
