import MuiFormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';

import { FormControlLabelProps } from './FormControlLabel.props';
import { FormControlLabelStyles } from './FormControlLabel.styles';

export const FormControlLabel: React.FC<FormControlLabelProps> = props => {
  const classes = FormControlLabelStyles();

  return <MuiFormControlLabel classes={classes} {...props} />;
};
