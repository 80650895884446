import { makeStyles } from '@mui/styles';

export const SimpleLinkStyles = makeStyles<{}>({
  root: {
    textDecoration: 'none',
    '&:link': {
      color: 'inherit',
    },
    '&:visited': {
      color: 'inherit',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
