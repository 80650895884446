import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getMediaQueryForPhone } from '@bpm/utils/MediaQuery';

export const FormControlLabelStyles = makeStyles<Theme>(theme => {
  const mobile = getMediaQueryForPhone(theme);

  return {
    label: {
      [mobile]: {
        fontSize: 14,
      },
    },
  };
});
