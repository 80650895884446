/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql';

import { DoctorModel, DoctorModelType } from './DoctorModel';
import { DoctorModelSelector } from './DoctorModel.base';
import { ModelBase } from './ModelBase';
import { PatientModel, PatientModelType } from './PatientModel';
import { PatientModelSelector } from './PatientModel.base';
import { RootStoreType } from './index';

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  doctor: DoctorModelType;
  patient: PatientModelType;
};

/**
 * UserBase
 * auto generated base class for the model UserModel.
 */
export const UserModelBase = withTypedRefs<Refs>()(
  ModelBase.named('User')
    .props({
      __typename: types.optional(types.literal('User'), 'User'),
      createdAt: types.union(types.undefined, types.frozen()),
      doctor: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => DoctorModel))),
      email: types.union(types.undefined, types.string),
      emailVerified: types.union(types.undefined, types.boolean),
      id: types.identifier,
      patient: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => PatientModel))),
      role: types.union(types.undefined, types.string),
      updatedAt: types.union(types.undefined, types.frozen()),
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>();
      },
    })),
);

export class UserModelSelector extends QueryBuilder {
  get createdAt() {
    return this.__attr(`createdAt`);
  }
  get email() {
    return this.__attr(`email`);
  }
  get emailVerified() {
    return this.__attr(`emailVerified`);
  }
  get id() {
    return this.__attr(`id`);
  }
  get role() {
    return this.__attr(`role`);
  }
  get updatedAt() {
    return this.__attr(`updatedAt`);
  }
  doctor(builder?: string | DoctorModelSelector | ((selector: DoctorModelSelector) => DoctorModelSelector)) {
    return this.__child(`doctor`, DoctorModelSelector, builder);
  }
  patient(builder?: string | PatientModelSelector | ((selector: PatientModelSelector) => PatientModelSelector)) {
    return this.__child(`patient`, PatientModelSelector, builder);
  }
}
export function selectFromUser() {
  return new UserModelSelector();
}

export const userModelPrimitives = selectFromUser().createdAt.email.emailVerified.role.updatedAt;
