/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { QueryBuilder } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { RootStoreType } from './index';

/**
 * PredictionChartOutputBase
 * auto generated base class for the model PredictionChartOutputModel.
 */
export const PredictionChartOutputModelBase = ModelBase.named('PredictionChartOutput')
  .props({
    __typename: types.optional(types.literal('PredictionChartOutput'), 'PredictionChartOutput'),
    xDatetime: types.union(types.undefined, types.array(types.string)),
    yDiastolic: types.union(types.undefined, types.array(types.number)),
    ySystolic: types.union(types.undefined, types.array(types.number)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>();
    },
  }));

export class PredictionChartOutputModelSelector extends QueryBuilder {
  get xDatetime() {
    return this.__attr(`xDatetime`);
  }
  get yDiastolic() {
    return this.__attr(`yDiastolic`);
  }
  get ySystolic() {
    return this.__attr(`ySystolic`);
  }
}
export function selectFromPredictionChartOutput() {
  return new PredictionChartOutputModelSelector();
}

export const predictionChartOutputModelPrimitives = selectFromPredictionChartOutput().xDatetime.yDiastolic.ySystolic;
