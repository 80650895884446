import React, { SuspenseProps } from 'react';

import { Loader } from '@bpm/ui-kit/components/Loader/Loader';

export const FullScreenLoader: React.FC = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
      <Loader size={150} />
    </div>
  );
};

export const FallbackElement: React.FC<Omit<SuspenseProps, 'fallback'>> = props => {
  return <React.Suspense {...props} fallback={<FullScreenLoader />} />;
};
