/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { QueryBuilder } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { RootStoreType } from './index';

/**
 * LoginOutputBase
 * auto generated base class for the model LoginOutputModel.
 */
export const LoginOutputModelBase = ModelBase.named('LoginOutput')
  .props({
    __typename: types.optional(types.literal('LoginOutput'), 'LoginOutput'),
    token: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>();
    },
  }));

export class LoginOutputModelSelector extends QueryBuilder {
  get token() {
    return this.__attr(`token`);
  }
}
export function selectFromLoginOutput() {
  return new LoginOutputModelSelector();
}

export const loginOutputModelPrimitives = selectFromLoginOutput().token;
