/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { QueryBuilder } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { RootStoreType } from './index';

/**
 * SuccessOutputBase
 * auto generated base class for the model SuccessOutputModel.
 */
export const SuccessOutputModelBase = ModelBase.named('SuccessOutput')
  .props({
    __typename: types.optional(types.literal('SuccessOutput'), 'SuccessOutput'),
    success: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>();
    },
  }));

export class SuccessOutputModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`);
  }
}
export function selectFromSuccessOutput() {
  return new SuccessOutputModelSelector();
}

export const successOutputModelPrimitives = selectFromSuccessOutput().success;
