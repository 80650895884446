import { applySnapshot, flow, types } from 'mobx-state-tree';

import { withGraphql } from '@bpm/mobx/extensions/withGraphql';
import {
  CardioLosesChartOutputModelType,
  CardioPieChartOutputModelType,
  PredictionChartOutputModelType,
  StatisticsChartOutputModelType,
} from '@bpm/mobx/graphql';
import { PredictionChartInput, StatisticsChartInput } from '@bpm/mobx/graphql/RootStore.base';
import { LoadingStatus } from '@bpm/mobx/models/LoadingStatus';
import { MSTInstance } from '@bpm/mobx/types/mst';
import { dropYear } from '@bpm/utils/strings';

const LineChartPointModel = types.model('LineChartPointModel', {
  x: types.union(types.number, types.string),
  y: types.number,
});

const PieChartDataModel = types.model('PieChartDataModel', {
  labels: types.array(types.string),
  data: types.array(types.number),
});

const ChartsModel = types
  .model('ChartsStore', {
    cardioPieModel: types.maybe(PieChartDataModel),
    cardioLosesModel: types.optional(types.array(types.array(LineChartPointModel)), [[]]),
    cardioLosesModelLabels: types.maybe(types.frozen()),
    cardioModel: types.optional(types.array(types.array(LineChartPointModel)), [[]]),
    pressureModel: types.optional(types.array(types.array(LineChartPointModel)), [[]]),
    predictionModel: types.optional(types.array(types.array(LineChartPointModel)), [[]]),

    cardioPieLoadingStatus: types.optional(LoadingStatus, {}),
    cardioLosesLoadingStatus: types.optional(LoadingStatus, {}),
    statisticsLoadingStatus: types.optional(LoadingStatus, {}),
    predictionLoadingStatus: types.optional(LoadingStatus, {}),
  })
  .extend(withGraphql)
  .actions(self => ({
    loadStatistics: flow(function* (filters: StatisticsChartInput) {
      self.statisticsLoadingStatus.start();
      const statisticsChartInput = filters;

      const data = yield self.graphql.queryStatistics({ statisticsChartInput }).promise;
      const statistics: StatisticsChartOutputModelType = data.statistics;

      if (!statistics) {
        return;
      }

      const pressureChartSystolic = statistics.ySystolic!.map((item, idx) => ({
        y: item,
        x: statistics.xSystolic![idx],
      }));
      const pressureChartDiastolic = statistics.yDiastolic!.map((item, idx) => ({
        y: item,
        x: statistics.xDiastolic![idx],
      }));
      const pressureModel = [pressureChartDiastolic, pressureChartSystolic];

      const cardioChartSystolic = statistics.yCardio!.map((item, idx) => ({ y: item, x: statistics.xSystolic![idx] }));
      const cardioChartDiastolic = statistics.yCardio!.map((item, idx) => ({
        y: item,
        x: statistics.xDiastolic![idx],
      }));
      const cardioModel = [cardioChartDiastolic, cardioChartSystolic];
      applySnapshot(self, { ...self, cardioModel, pressureModel });

      self.statisticsLoadingStatus.finish();
    }),
    loadCardioPie: flow(function* (statisticsChartInput: StatisticsChartInput) {
      self.cardioPieLoadingStatus.start();
      const response = yield self.graphql.queryCardioPieChartStatistics({ statisticsChartInput }).promise;
      const cardioPieChartStatistics: CardioPieChartOutputModelType[] = response.cardioPieChartStatistics;

      if (!cardioPieChartStatistics) {
        return;
      }

      const [labels, data] = cardioPieChartStatistics.reduce<[string[], number[]]>(
        (accumulate, current) => [
          [...accumulate[0], current.label as string],
          [...accumulate[1], current.percent as number],
        ],
        [[], []],
      );

      const cardioPieModel = {
        labels,
        data,
      };

      applySnapshot(self, { ...self, cardioPieModel });

      self.cardioPieLoadingStatus.finish();
    }),
    loadCardioLoses: flow(function* (statisticsChartInput: StatisticsChartInput) {
      self.cardioLosesLoadingStatus.start();
      const data = yield self.graphql.queryCardioLosesChartStatistics({ statisticsChartInput }).promise;
      const cardioLosesChartStatistics: CardioLosesChartOutputModelType = data.cardioLosesChartStatistics;

      if (!cardioLosesChartStatistics) {
        return;
      }

      const cardioLosesModelLabels = cardioLosesChartStatistics.xBreak!.reduce(
        (accumulate, current, idx) => ({
          ...accumulate,
          [current]: cardioLosesChartStatistics.xLabel![idx],
        }),
        {},
      );

      const chartSystolicDiastolic = cardioLosesChartStatistics
        .yCardio!.map((item, idx) => ({
          y: item,
          x: cardioLosesChartStatistics.xSysDias![idx],
        }))
        .sort((a, b) => a.x - b.x);
      const cardioLosesModel = [chartSystolicDiastolic];
      applySnapshot(self, { ...self, cardioLosesModel, cardioLosesModelLabels });

      self.cardioLosesLoadingStatus.finish();
    }),
    loadPrediction: flow(function* (predictionChartInput: PredictionChartInput) {
      self.predictionLoadingStatus.start();

      const data = yield self.graphql.queryPrediction({ predictionChartInput }).promise;
      const prediction: PredictionChartOutputModelType = data.prediction;

      if (!prediction) {
        return;
      }

      const predictionChartDiastolic = prediction.yDiastolic!.map((item, idx) => ({
        y: item,
        x: dropYear(prediction.xDatetime![idx]),
      }));
      const predictionChartSystolic = prediction.ySystolic!.map((item, idx) => ({
        y: item,
        x: dropYear(prediction.xDatetime![idx]),
      }));
      const predictionModel = [predictionChartDiastolic, predictionChartSystolic];
      applySnapshot(self, { ...self, predictionModel });

      self.predictionLoadingStatus.finish();
    }),
  }));

type ChartsModelType = typeof ChartsModel;

interface ChartsStoreTypeInterface extends ChartsModelType {}

export interface ChartsStoreInterface extends MSTInstance<ChartsStoreTypeInterface> {}

export const ChartsStore: ChartsStoreTypeInterface = ChartsModel;
