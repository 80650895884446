import { makeStyles } from '@mui/styles';

export const LogoStyles = makeStyles({
  root: {
    position: 'relative',
  },
  logo: {
    position: 'relative',
    zIndex: 2,
  },
  beta: {
    position: 'absolute',
    top: -6,
    right: -24,
    padding: '2px 4px',
    borderRadius: 4,
    fontSize: 11,
    backgroundColor: '#fdd835',
  },
  betaSmall: {
    top: 2,
    right: -18,
    padding: '1px 2px',
    fontSize: 8,
  },
});
