import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getMediaQueryForPhone } from '@bpm/utils/MediaQuery';

import { colors } from '../../theme';

import { TypographyProps } from './Typography.props';

export const headingFontFamily = "'HelveticaNeue', Arial";
export const bodyFontFamily = "'Helvetica', Arial";

export const TypographyStyles = makeStyles<Theme, TypographyProps>(theme => {
  const mobile = getMediaQueryForPhone(theme);

  return {
    root: ({ color = 'black', isBold, align, textDecoration, cursor }) => ({
      margin: 0,
      color: colors[color],
      textAlign: align,
      fontWeight: isBold ? 'bold' : 'normal',
      textDecoration,
      cursor,
    }),
    hero: {
      fontFamily: headingFontFamily,
      fontSize: 40,
    },
    heading: {
      fontFamily: headingFontFamily,
      fontSize: 24,
      [mobile]: {
        fontSize: 18,
      },
    },
    body: {
      fontFamily: bodyFontFamily,
      fontSize: 16,
      [mobile]: {
        fontSize: 14,
      },
    },
    bodySmall: {
      fontFamily: bodyFontFamily,
      fontSize: 13,
    },
  };
});
