import { EffectCallback, useEffect } from 'react';

/**
 * ```ts
 * Custom useEffect hook, which is invoked only once (on mount)
 * ```
 */
function useOnMount(effect: EffectCallback) {
  useEffect(effect, []); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useOnMount;
