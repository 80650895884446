import { Avatar } from '@mui/material';
import React from 'react';

import { DrawerLayout } from '@bpm/pages/protected/components/DashboardLayout/DrawerLayout/DrawerLayout';
import { HeaderMenuContent } from '@bpm/pages/protected/components/DashboardLayout/HeaderMenuContent';

import { MobileUserMenuProps } from './MobileUserMenu.props';

export const MobileUserMenu: React.FC<MobileUserMenuProps> = props => {
  const { className, user } = props;
  return (
    <DrawerLayout anchor="right" DrawerMenuContentComponent={HeaderMenuContent}>
      <Avatar alt={user} className={className}>
        {user}
      </Avatar>
    </DrawerLayout>
  );
};
