import { IconButton } from '@mui/material';
import React, { useState } from 'react';

import { Drawer } from '@bpm/ui-kit/components/Drawer/Drawer';

import { DrawerLayoutProps } from './DrawerLayout.props';

export const DrawerLayout: React.FC<DrawerLayoutProps> = props => {
  const { children, anchor, DrawerMenuContentComponent } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <IconButton onClick={openDrawer}>{children}</IconButton>

      <Drawer open={isDrawerOpen} onClose={closeDrawer} anchor={anchor}>
        <DrawerMenuContentComponent onClose={closeDrawer} />
      </Drawer>
    </>
  );
};
