import { makeStyles } from '@mui/styles';

import { CheckboxHelperTextProps } from '@bpm/ui-kit/components/CheckboxHelperText/CheckboxHelperText.props';

import { colors } from '../../theme';

export const CheckboxHelperTextStyles = makeStyles<{}, CheckboxHelperTextProps>({
  root: ({ error }) => ({
    marginLeft: 32,
    fontSize: 12,
    fontFamily: 'Helvetica',
    color: error ? colors.lightCoral : colors.black,
  }),
});
