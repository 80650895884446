import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getMediaQueryForPhone } from '@bpm/utils/MediaQuery';

import { colors } from '../../theme';

import { ButtonProps } from './Button.props';

export const ButtonStyles = makeStyles<Theme, ButtonProps>(theme => {
  const mobile = getMediaQueryForPhone(theme);

  return {
    root: ({ disabled }) => ({
      borderRadius: 12,
      fontWeight: 'bold',
      letterSpacing: 'normal',
      lineHeight: '16px',
      textTransform: 'initial',
      pointerEvents: disabled ? 'none' : 'initial',
      opacity: disabled ? 0.35 : 1,
    }),
    contained: {
      color: colors.white,
    },
    containedPrimary: {
      backgroundColor: colors.primary,
      boxShadow: `0 10px 24px 0 ${colors.primary}99`,

      '&:hover': {
        backgroundColor: colors.primaryHover,
        boxShadow: `0 10px 24px 0 ${colors.primaryHover}99`,
      },
    },
    containedSecondary: {
      backgroundColor: colors.secondary,
      boxShadow: `0 10px 24px 0 ${colors.secondary}99`,

      '&:hover': {
        backgroundColor: colors.secondaryHover,
        boxShadow: `0 10px 24px 0 ${colors.secondaryHover}99`,
      },
    },
    text: {
      backgroundColor: 'transparent',
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
    textPrimary: {
      color: colors.primary,

      '&:hover': {
        color: colors.primaryHover,
      },
    },
    textSecondary: {
      color: colors.secondary,

      '&:hover': {
        color: colors.secondaryHover,
      },
    },
    outlined: {
      borderWidth: 2,

      '&:hover': {
        borderWidth: 2,
      },
    },
    outlinedPrimary: {
      color: colors.primary,
      borderColor: colors.primary,

      '&:hover': {
        color: colors.primaryHover,
        borderColor: colors.primaryHover,
      },
    },
    outlinedSecondary: {
      color: colors.secondary,
      borderColor: colors.secondary,

      '&:hover': {
        color: colors.secondaryHover,
        borderColor: colors.secondaryHover,
      },
    },
    sizeSmall: {
      padding: '8px 16px',
      fontSize: 14,
    },
    sizeMedium: {
      padding: '16px 48px',
      fontSize: 16,
      [mobile]: {
        padding: '14px 48px',
        fontSize: 15,
      },
    },
    sizeLarge: {
      padding: '20px 52px',
      fontSize: 20,
    },
    outlinedSizeSmall: {
      padding: '6px 14px',
    },
    outlinedSizeMedium: {
      padding: '14px 46px',
    },
    outlinedSizeLarge: {
      padding: '18px 50px',
    },
  };
});
