import { useContext } from 'react';

import type { RootStoreShape } from './RootStore';
import { RootStoreContext } from './RootStore';

export function useStores(): RootStoreShape {
  const store = useContext(RootStoreContext);

  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }

  return store as unknown as RootStoreShape;
}
