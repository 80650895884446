/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { PatientModel, PatientModelType } from './PatientModel';
import { PatientModelSelector } from './PatientModel.base';
import { RootStoreType } from './index';

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  patient: PatientModelType;
};

/**
 * TimeserieBase
 * auto generated base class for the model TimeserieModel.
 */
export const TimeserieModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Timeserie')
    .props({
      __typename: types.optional(types.literal('Timeserie'), 'Timeserie'),
      activity: types.union(types.undefined, types.number),
      arrhythmia: types.union(types.undefined, types.boolean),
      comment: types.union(types.undefined, types.null, types.string),
      createdAt: types.union(types.undefined, types.frozen()),
      diastolic: types.union(types.undefined, types.number),
      headAche: types.union(types.undefined, types.boolean),
      healthyDiet: types.union(types.undefined, types.boolean),
      id: types.identifier,
      measureDate: types.union(types.undefined, types.frozen()),
      patient: types.union(types.undefined, MSTGQLRef(types.late((): any => PatientModel))),
      stress: types.union(types.undefined, types.boolean),
      systolic: types.union(types.undefined, types.number),
      updatedAt: types.union(types.undefined, types.frozen()),
      weakness: types.union(types.undefined, types.boolean),
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>();
      },
    })),
);

export class TimeserieModelSelector extends QueryBuilder {
  get activity() {
    return this.__attr(`activity`);
  }
  get arrhythmia() {
    return this.__attr(`arrhythmia`);
  }
  get comment() {
    return this.__attr(`comment`);
  }
  get createdAt() {
    return this.__attr(`createdAt`);
  }
  get diastolic() {
    return this.__attr(`diastolic`);
  }
  get headAche() {
    return this.__attr(`headAche`);
  }
  get healthyDiet() {
    return this.__attr(`healthyDiet`);
  }
  get id() {
    return this.__attr(`id`);
  }
  get measureDate() {
    return this.__attr(`measureDate`);
  }
  get stress() {
    return this.__attr(`stress`);
  }
  get systolic() {
    return this.__attr(`systolic`);
  }
  get updatedAt() {
    return this.__attr(`updatedAt`);
  }
  get weakness() {
    return this.__attr(`weakness`);
  }
  patient(builder?: string | PatientModelSelector | ((selector: PatientModelSelector) => PatientModelSelector)) {
    return this.__child(`patient`, PatientModelSelector, builder);
  }
}
export function selectFromTimeserie() {
  return new TimeserieModelSelector();
}

export const timeserieModelPrimitives =
  selectFromTimeserie().activity.arrhythmia.comment.createdAt.diastolic.headAche.healthyDiet.measureDate.stress.systolic
    .updatedAt.weakness;
