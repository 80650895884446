/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { IObservableArray } from 'mobx';
import { types } from 'mobx-state-tree';
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { TimeserieModel, TimeserieModelType } from './TimeserieModel';
import { TimeserieModelSelector } from './TimeserieModel.base';
import { UserModel, UserModelType } from './UserModel';
import { UserModelSelector } from './UserModel.base';
import { RootStoreType } from './index';

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  timeseries: IObservableArray<TimeserieModelType>;
  user: UserModelType;
};

/**
 * PatientBase
 * auto generated base class for the model PatientModel.
 */
export const PatientModelBase = withTypedRefs<Refs>()(
  ModelBase.named('Patient')
    .props({
      __typename: types.optional(types.literal('Patient'), 'Patient'),
      age: types.union(types.undefined, types.number),
      cholesterol: types.union(types.undefined, types.null, types.number),
      comment: types.union(types.undefined, types.null, types.string),
      createdAt: types.union(types.undefined, types.frozen()),
      currentTreatments: types.union(types.undefined, types.null, types.array(types.string)),
      firstName: types.union(types.undefined, types.string),
      gender: types.union(types.undefined, types.string),
      glucose: types.union(types.undefined, types.null, types.number),
      habitsAndComplaints: types.union(types.undefined, types.null, types.array(types.string)),
      height: types.union(types.undefined, types.number),
      hereditaryPressure: types.union(types.undefined, types.number),
      id: types.identifier,
      lastName: types.union(types.undefined, types.string),
      pregnancy: types.union(types.undefined, types.boolean),
      prescriptionDrugs: types.union(types.undefined, types.null, types.array(types.string)),
      presentDiseases: types.union(types.undefined, types.null, types.array(types.string)),
      sideEffects: types.union(types.undefined, types.boolean),
      symptoms: types.union(types.undefined, types.null, types.array(types.string)),
      temperature: types.union(types.undefined, types.number),
      timeseries: types.union(
        types.undefined,
        types.null,
        types.array(MSTGQLRef(types.late((): any => TimeserieModel))),
      ),
      updatedAt: types.union(types.undefined, types.frozen()),
      user: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => UserModel))),
      weight: types.union(types.undefined, types.number),
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>();
      },
    })),
);

export class PatientModelSelector extends QueryBuilder {
  get age() {
    return this.__attr(`age`);
  }
  get cholesterol() {
    return this.__attr(`cholesterol`);
  }
  get comment() {
    return this.__attr(`comment`);
  }
  get createdAt() {
    return this.__attr(`createdAt`);
  }
  get currentTreatments() {
    return this.__attr(`currentTreatments`);
  }
  get firstName() {
    return this.__attr(`firstName`);
  }
  get gender() {
    return this.__attr(`gender`);
  }
  get glucose() {
    return this.__attr(`glucose`);
  }
  get habitsAndComplaints() {
    return this.__attr(`habitsAndComplaints`);
  }
  get height() {
    return this.__attr(`height`);
  }
  get hereditaryPressure() {
    return this.__attr(`hereditaryPressure`);
  }
  get id() {
    return this.__attr(`id`);
  }
  get lastName() {
    return this.__attr(`lastName`);
  }
  get pregnancy() {
    return this.__attr(`pregnancy`);
  }
  get prescriptionDrugs() {
    return this.__attr(`prescriptionDrugs`);
  }
  get presentDiseases() {
    return this.__attr(`presentDiseases`);
  }
  get sideEffects() {
    return this.__attr(`sideEffects`);
  }
  get symptoms() {
    return this.__attr(`symptoms`);
  }
  get temperature() {
    return this.__attr(`temperature`);
  }
  get updatedAt() {
    return this.__attr(`updatedAt`);
  }
  get weight() {
    return this.__attr(`weight`);
  }
  timeseries(
    builder?: string | TimeserieModelSelector | ((selector: TimeserieModelSelector) => TimeserieModelSelector),
  ) {
    return this.__child(`timeseries`, TimeserieModelSelector, builder);
  }
  user(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) {
    return this.__child(`user`, UserModelSelector, builder);
  }
}
export function selectFromPatient() {
  return new PatientModelSelector();
}

export const patientModelPrimitives =
  selectFromPatient().age.cholesterol.comment.createdAt.currentTreatments.firstName.gender.glucose.habitsAndComplaints
    .height.hereditaryPressure.lastName.pregnancy.prescriptionDrugs.presentDiseases.sideEffects.symptoms.temperature
    .updatedAt.weight;
