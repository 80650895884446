import MuiCard from '@mui/material/Card';
import React from 'react';

import { CardProps } from './Card.props';
import { CardStyles } from './Card.styles';

export const Card: React.FC<CardProps> = props => {
  const { ...rest } = props;
  const classes = CardStyles(props);

  return <MuiCard classes={classes} {...rest} />;
};
