import clsx from 'clsx';
import React from 'react';

import { SimpleLinkProps } from './SimpleLink.props';
import { SimpleLinkStyles } from './SimpleLink.styles';

export const SimpleLink: React.FC<SimpleLinkProps> = props => {
  const { children, className, ...rest } = props;
  const classes = SimpleLinkStyles();

  return (
    <a className={clsx(classes.root, className)} {...rest}>
      {children}
    </a>
  );
};
