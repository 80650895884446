/* This is a mst-gql generated file, don't modify it manually */

/* eslint-disable */

/* tslint:disable */
import { types } from 'mobx-state-tree';
import { QueryBuilder } from 'mst-gql';

import { ModelBase } from './ModelBase';
import { RootStoreType } from './index';

/**
 * RiskLevelChartOutputBase
 * auto generated base class for the model RiskLevelChartOutputModel.
 */
export const RiskLevelChartOutputModelBase = ModelBase.named('RiskLevelChartOutput')
  .props({
    __typename: types.optional(types.literal('RiskLevelChartOutput'), 'RiskLevelChartOutput'),
    patientCardioRiskLevel: types.union(types.undefined, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>();
    },
  }));

export class RiskLevelChartOutputModelSelector extends QueryBuilder {
  get patientCardioRiskLevel() {
    return this.__attr(`patientCardioRiskLevel`);
  }
}
export function selectFromRiskLevelChartOutput() {
  return new RiskLevelChartOutputModelSelector();
}

export const riskLevelChartOutputModelPrimitives = selectFromRiskLevelChartOutput().patientCardioRiskLevel;
