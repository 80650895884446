import { ClickAwayListener, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useMatch } from 'react-router-dom';

import { Spacer } from '@bpm/components/Spacer/Spacer';
import { useTranslation } from '@bpm/i18n';
import { useStores } from '@bpm/mobx/useStores';
import { routeTable } from '@bpm/routes/constants';
import { Card } from '@bpm/ui-kit/components/Card';
import { Popper } from '@bpm/ui-kit/components/Popper';
import { Body } from '@bpm/ui-kit/components/Typography';
import { IconDashboardStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconDashboardStandard';
import { IconImportStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconImportStandard';
import { IconPatientsStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconPatientsStandard';
import { IconUserStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconUserStandard';
import { usePhoneBreakpoint } from '@bpm/utils/hooks/theme';
import useNavigation from '@bpm/utils/hooks/useNavigation';

import { MenuItemProps } from './DashboardLayout.props';
import { DashboardLayoutStyles, MenuItemStyles } from './DashboardLayout.styles';
import { ImportDataContent } from './ImportDataContent';
import { SidebarProps } from './Sidebar.props';

const MenuItem: React.FC<MenuItemProps> = props => {
  const { icon, text, active, disabled, onClick } = props;
  const isActiveRoute = Boolean(active);
  const classes = MenuItemStyles({ isActiveRoute });

  const getBodyColor = () => {
    if (isActiveRoute) {
      return 'primary';
    }

    if (disabled) {
      return 'doveGray';
    }

    return 'black';
  };

  return (
    <Spacer mb={8}>
      <Grid item container alignItems="center" onClick={disabled ? undefined : onClick}>
        <Spacer mr={12}>
          <IconButton className={classes.root} disabled={disabled}>
            {icon}
          </IconButton>
        </Spacer>
        <Body cursor={disabled ? 'not-allowed' : 'pointer'} color={getBodyColor()}>
          {text}
        </Body>
      </Grid>
    </Spacer>
  );
};

export const Sidebar: React.FC<SidebarProps> = props => {
  const classes = DashboardLayoutStyles();
  const { onClose } = props;

  const { localePath, t } = useTranslation('menuItems');
  const { switchTo } = useNavigation();
  const { authStore } = useStores();
  const [isImportPopperOpen, setIsImportPopperOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const isMobile = usePhoneBreakpoint();

  const { isDoctor } = authStore.user!;

  const onImportItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsImportPopperOpen(!isImportPopperOpen);
    setAnchorElement(event.currentTarget);
  };

  const onClickAwayClick = () => {
    setIsImportPopperOpen(false);
  };

  const isActiveRoute = (route: string) => useMatch(`${localePath(route)}/*`);

  const switchToRoute = (route: string) => {
    switchTo(route);
    onClose && onClose();
  };

  const doctorMenuItems: MenuItemProps[] = [
    {
      icon: <IconDashboardStandard className={classes.svgIcon} />,
      text: t('dashboard'),
      onClick: () => switchToRoute(routeTable.dashboard),
      active: isActiveRoute(routeTable.dashboard),
    },
    {
      icon: <IconPatientsStandard className={classes.svgIcon} />,
      text: t('patients'),
      onClick: () => switchToRoute(routeTable.patients),
      active: isActiveRoute(routeTable.patients),
    },
    {
      icon: <IconImportStandard className={classes.svgIcon} />,
      text: t('importData'),
      onClick: event => event && !isMobile && onImportItemClick(event),
      active: isImportPopperOpen,
    },
  ];

  const patientMenuItems: MenuItemProps[] = [
    {
      icon: <IconUserStandard className={classes.svgIcon} />,
      text: t('myProfile'),
      onClick: () => authStore.user?.patient && switchToRoute(routeTable.patient),
      active: isActiveRoute(routeTable.patient),
      disabled: !authStore.user?.patient,
    },
  ];

  const menuItems = isDoctor ? doctorMenuItems : patientMenuItems;

  return (
    <Card className={classes.menuContainer}>
      <Spacer py={isMobile ? 20 : 40} pl={isMobile ? 16 : 22} pr={28}>
        {menuItems.map(item => (
          <MenuItem key={item.text} {...item} />
        ))}
      </Spacer>
      <Popper open={isImportPopperOpen} anchorEl={anchorElement} placement="right-start">
        <ClickAwayListener onClickAway={onClickAwayClick}>
          <div className={classes.importPopperContainer}>
            <ImportDataContent onClose={onClickAwayClick} />
          </div>
        </ClickAwayListener>
      </Popper>
    </Card>
  );
};
