import { getRoot, IStateTreeNode } from 'mobx-state-tree';

import type { RootStoreShape } from 'mobx/RootStore';

import { RootStoreType as GraphqlStore } from '../graphql';

export const withGraphql = (self: IStateTreeNode) => ({
  views: {
    get graphql(): GraphqlStore {
      return getRoot<RootStoreShape>(self).graphql;
    },
  },
});
