import { Formik, Form } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Spacer } from '@bpm/components/Spacer/Spacer';
import { useTranslation } from '@bpm/i18n';
import { useStores } from '@bpm/mobx/useStores';
import { routeTable } from '@bpm/routes/constants';
import { Button } from '@bpm/ui-kit/components/Button/Button';
import { Input } from '@bpm/ui-kit/components/Input/Input';
import { Heading, Body } from '@bpm/ui-kit/components/Typography/Typography';
import { IconEyeHideStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconEyeHideStandard';
import { IconEyeVewStandard } from '@bpm/ui-kit/components/icons/iconComponents/IconEyeVewStandard';
import { colors } from '@bpm/ui-kit/theme';
import useNavigation from '@bpm/utils/hooks/useNavigation';

import { FormikHelpers } from 'formik/dist/types';

import { getEntryPointPath } from '../login';

import { REDIRECT_DELAY } from './index.constants';
import { formInitialValues } from './index.formik';
import { FormFieldsProps } from './index.props';

const ResetPasswordForm: React.FC = observer(() => {
  const { t } = useTranslation('signing');
  const { t: validationsT } = useTranslation('validations');
  const { authStore } = useStores();
  const [searchParams] = useSearchParams();
  const { switchTo } = useNavigation();

  const [showPassword, setShowPassword] = useState(false);

  const { resetPassword, loadingStatus } = authStore;

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, validationsT('auth.passwordLength'))
      .required(validationsT('auth.passwordRequired')),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], validationsT('auth.passwordNotMatch'))
      .required(validationsT('auth.repeatPasswordRequired')),
  });

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      const path = getEntryPointPath(authStore.user);
      path && switchTo(path);
    }, REDIRECT_DELAY);

    return () => {
      clearTimeout(redirectTimer);
    };
  }, [authStore.user, switchTo]);

  const submitForm = async (values: FormFieldsProps, formikHelpers: FormikHelpers<FormFieldsProps>) => {
    const { newPassword, repeatPassword } = values;

    await resetPassword(searchParams.get('token') ?? '', newPassword, repeatPassword);

    if (loadingStatus.isSuccess) {
      formikHelpers.resetForm();
    }
  };

  const IconEyeView = <IconEyeVewStandard color={colors.easterBlue} />;
  const IconEyeHide = <IconEyeHideStandard color={colors.easterBlue} />;

  const handleCancel = () => {
    switchTo(routeTable.login);
  };

  return (
    <Formik initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={submitForm}>
      {form => (
        <Form onSubmit={form.handleSubmit} noValidate>
          <Heading>{t('resetPassword')}</Heading>

          <Spacer my={20}>
            <Input
              fullWidth
              type={showPassword ? 'text' : 'password'}
              name="newPassword"
              value={form.values.newPassword}
              onChange={form.handleChange}
              placeholder={t('newPassword')}
              onAdornmentClick={() => setShowPassword(!showPassword)}
              endAdornment={showPassword ? IconEyeView : IconEyeHide}
              error={form.touched.newPassword && !!form.errors.newPassword}
              helperText={form.touched.newPassword && form.errors.newPassword}
            />
          </Spacer>

          <Spacer my={20}>
            <Input
              fullWidth
              type={showPassword ? 'text' : 'password'}
              name="repeatPassword"
              value={form.values.repeatPassword}
              onChange={form.handleChange}
              placeholder={t('repeatPassword')}
              onAdornmentClick={() => setShowPassword(!showPassword)}
              endAdornment={showPassword ? IconEyeView : IconEyeHide}
              error={form.touched.repeatPassword && !!form.errors.repeatPassword}
              helperText={form.touched.repeatPassword && form.errors.repeatPassword}
            />
          </Spacer>

          <Spacer my={20}>
            <Button fullWidth type="submit" disabled={form.isSubmitting}>
              {t('resetPassword')}
            </Button>
          </Spacer>

          <Spacer my={20}>
            <Button fullWidth variant="outlined" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </Spacer>

          {loadingStatus.isSuccess && (
            <Body isBold color="persianGreen">
              {t('resetPasswordSuccess')}
            </Body>
          )}

          {loadingStatus.isError && (
            <Body isBold color="lightCoral">
              {loadingStatus.error}
            </Body>
          )}
        </Form>
      )}
    </Formik>
  );
});

const ResetPassword: React.FC = () => <ResetPasswordForm />;

export default ResetPassword;
