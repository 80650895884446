import React from 'react';

import { CheckboxHelperTextProps } from '@bpm/ui-kit/components/CheckboxHelperText/CheckboxHelperText.props';
import { CheckboxHelperTextStyles } from '@bpm/ui-kit/components/CheckboxHelperText/CheckboxHelperText.styles';

export const CheckboxHelperText: React.FC<CheckboxHelperTextProps> = props => {
  const { children } = props;
  const classes = CheckboxHelperTextStyles(props);

  if (!children) {
    return null;
  }

  return <div className={classes.root}>{children}</div>;
};
