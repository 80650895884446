import { types } from 'mobx-state-tree';

import { MSTInstance } from '../types/mst';

const StatusMap = {
  idle: 'Idle',
  loading: 'Loading',
  loaded: 'Loaded',
  error: 'Error',
};

const Model = types
  .model('LoadingStatus', {
    status: types.optional(types.enumeration(Object.keys(StatusMap)), 'idle'),
    error: types.maybe(types.string),
  })
  .views(self => ({
    get isLoading(): boolean {
      return self.status === 'loading';
    },
    get isLoaded(): boolean {
      return self.status === 'loaded';
    },
    get isIdle(): boolean {
      return self.status === 'idle';
    },
    get isError(): boolean {
      return self.status === 'error';
    },
  }))
  .views(self => ({
    get isSuccess(): boolean {
      return self.isLoaded;
    },
  }))
  .actions(self => ({
    start() {
      self.status = 'loading';
      self.error = undefined;
    },
    finish() {
      self.status = 'loaded';
    },
    fail(error: string) {
      self.status = 'error';
      self.error = error;
    },
    reset() {
      self.status = 'idle';
      self.error = undefined;
    },
  }));

type ModelType = typeof Model;
interface ModelTypeInterface extends ModelType {}
export interface LoadingStatusInterface extends MSTInstance<ModelTypeInterface> {}
export const LoadingStatus: ModelTypeInterface = Model;
