import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { compile, pathToRegexp } from 'path-to-regexp';
import { initReactI18next, TFunction, useTranslation as useBaseTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Languages, supportedLngs } from '@bpm/i18n/languages';
import { camelize } from '@bpm/utils/strings';

import en from './locales/en.json';
import ka from './locales/ka.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: Languages.EN,
    resources: { en, ka },
    supportedLngs,
    interpolation: {
      escapeValue: false,
    },
  });

export const useTranslation = (ns?: string | string[]) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { t } = useBaseTranslation(ns);

  const currentLanguage = i18n.language;

  const translate: TFunction = (key: string, options) => t(camelize(key), options);

  const generatePathForLanguage = (locale: string, path: string) => {
    const ROUTE = '/:locale/:path*';
    const definePath = compile(ROUTE);
    const routeComponents = pathToRegexp(ROUTE).exec(path);

    let subPaths = null;
    if (routeComponents && routeComponents[2]) {
      subPaths = routeComponents[2].split('/');
    }

    return definePath({
      locale,
      path: subPaths,
    });
  };

  const changeLanguage = (lng: string) => {
    if (!supportedLngs.includes(lng as Languages)) {
      return;
    }
    const newPath = generatePathForLanguage(lng, pathname);
    i18n.changeLanguage(lng).then(() => navigate(`${newPath}${search}`, { replace: true }));
  };

  const localePath = (path: string) => `/${currentLanguage}${path}`;

  return {
    t: translate,
    localePath,
    changeLanguage,
    currentLanguage,
  };
};

export default i18n;
